import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import { DatePicker, Container } from './styles';

export default function MaterialUIPickers({ handleDateChange, label, value }) {
  return (
    <Container>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          // id="date-picker-inline"
          label={label}
          value={value}
          onChange={date => handleDateChange(date)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </MuiPickersUtilsProvider>
    </Container>
  );
}
MaterialUIPickers.propTypes = {
  handleDateChange: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.string,
};
MaterialUIPickers.defaultProps = {
  handleDateChange: () => {},
  label: 'label',
  value: 'dd/mm/yyyy',
};
