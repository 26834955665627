import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

import { colors } from '../../styles';

export const Container = styled.div`
  /* largura maxima */
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 630px;
  border-radius: 4px;
  box-shadow: 1px 1px 10px 10px rgba(0, 0, 0, 0.1);
  padding: 0;
  margin: 10px;

  h1 {
    font-size: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    svg {
      margin-right: 10px;
    }
  }
`;
export const HeaderTable = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 90px;
  align-items: center;
  padding-left: 20px;
`;
export const ButtonExcel = styled.strong``;

export const ContainerButtonTotal = styled.div`
  display: flex;
  flex-direction: row;
`;
export const ContainerButton = styled.div`
  padding: 2px;
  button {
    border: 0;
    background: ${colors.secundary};
    width: 60px;
    padding: 10px;
    border-radius: 8px;
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
`;
export const FieldText = styled(TextField)`
  width: 250px;
`;

export const ContainerInput = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* input {
    border: 1px solid #ddd;
    border-radius: 4px;
    color: #666;
    padding: 10px;
    width: 250px;
    margin-right: 2px;
  } */
  button {
    border: 0;
    background: ${colors.secundary};
    margin-right: 2px;
    padding: 10px;
    border-radius: 8px;
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
`;
export const ContainerEmission = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;
  /* background: #555; */
  justify-content: center;
  align-items: center;
  /* input {
    border: 1px solid #ddd;
    border-radius: 4px;
    color: #666;
    padding: 10px;
  } */
`;
export const ButtonEmission = styled.button`
  border: 0;
  background: ${colors.secundary};
  margin-left: 2px;
  padding: 10px;
  height: 40px;
  margin-top: 10px;
  border-radius: 8px;
  transition: opacity 0.2s;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

export const ContainerValue = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
`;

export const TextPrice = styled.strong``;

export const PriceTotal = styled.strong`
  padding: 1px;
  font-size: 24px;
`;
