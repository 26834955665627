import React from 'react';
import PropTypes from 'prop-types';
import { Container, Billings } from './styles';

export default function GridBillings({ data, id, moreDetails }) {
  return (
    <Container>
      <Billings id={id}>
        {data.length === 0 ? null : (
          <>
            <thead>
              <tr>
                <th>Data de emissão</th>
                <th>Nota</th>
                <th>Cliente</th>
                <th>Condição de pagamento</th>
              </tr>
            </thead>
            <tbody>
              {data.map(note => (
                <tr
                  onClick={() => {
                    moreDetails(note);
                  }}
                >
                  <div>
                    <strong>{note.emissao}</strong>
                  </div>

                  <td>
                    <strong>{note.nota}</strong>
                  </td>
                  <td>
                    <strong>{note.nome_razao}</strong>
                  </td>
                  <td>
                    <strong>{note.descricao}</strong>
                  </td>
                </tr>
              ))}
            </tbody>
          </>
        )}
      </Billings>
    </Container>
  );
}
GridBillings.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  // print: PropTypes.func,
  moreDetails: PropTypes.func,
  id: PropTypes.string,
};
GridBillings.defaultProps = {
  data: {},
  // print: () => {},
  moreDetails: () => {},
  id: 'table-billings',
};
