import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

export const Container = styled.div`
  /* largura maxima */
  max-width: 450px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin: 80px auto;
`;
export const AreaImage = styled.div`
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
`;

export const Image = styled.img`
  height: 150px;
`;

export const ContainerInput = styled.div`
  margin-top: 20px;

  margin-bottom: 20px;
`;

export const Input = styled(TextField)`
  width: 100%;
`;
export const Title = styled.h1`
  font-size: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 0 10px 0;
`;

export const Text = styled.span`
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 200;
  text-align: center;
`;
