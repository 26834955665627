import { all } from 'redux-saga/effects';

import login from './login/sagas';
import home from './home/sagas';
import orders from './orders/sagas';
import params from './params/sagas';
import users from './users/sagas';
import exit from './exit/sagas';
import validatorclient from './validatorclient/sagas';
import duplicate from './duplicate/sagas';
import note from './note/sagas';

export default function* rootSaga() {
  return yield all([
    login,
    home,
    orders,
    params,
    users,
    exit,
    validatorclient,
    duplicate,
    note,
  ]);
}
