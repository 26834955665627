import React from 'react';
import PropTypes from 'prop-types';
import { Container, Duplicates, IconPrint } from './styles';
import { formatPrice, convertDateApiString } from '../../utils/format';

export default function GridDuplicate({
  duplicates,
  PrintSlips,
  download,
  id,
}) {
  return (
    <Container>
      {duplicates.length === 0 ? null : (
        <Duplicates>
          <thead>
            <tr>
              <th>Número da duplicata</th>
              <th>Série</th>
              <th>Banco</th>
              <th>Emissão</th>
              <th>Vencimento</th>
              <th>Valor da duplicata</th>
            </tr>
          </thead>
          <tbody>
            {duplicates.map(element => (
              <tr>
                <div>
                  <a id={id} download={download} /> {/* eslint-disable-line */}
                  <button
                    type="button"
                    onClick={() => {
                      PrintSlips(element);
                    }}
                  >
                    <IconPrint size={24} />
                  </button>
                  <strong>{element.nf_nro}</strong>
                </div>

                <td>
                  <strong>{element.serie_duplicata}</strong>
                </td>
                <td>
                  <strong>{element.banco.descricao}</strong>
                </td>
                <td>
                  <strong>
                    {convertDateApiString(element.emissao_duplicata)}
                  </strong>
                </td>
                <td>
                  <strong>
                    {convertDateApiString(element.vencimento_duplicata)}
                  </strong>
                </td>
                <td>
                  <strong>{formatPrice(element.valor_duplicata)}</strong>
                </td>
              </tr>
            ))}
          </tbody>
        </Duplicates>
      )}
    </Container>
  );
}
GridDuplicate.propTypes = {
  duplicates: PropTypes.arrayOf(PropTypes.object),
  PrintSlips: PropTypes.func,
  // download: PropTypes.string,
  id: PropTypes.string,
  download: PropTypes.string,
};
GridDuplicate.defaultProps = {
  duplicates: {},
  PrintSlips: () => {},
  // download: '',
  id: 'linkDoPDF',
  download: 'boleto.pdf',
};
