import React from 'react';
import PropTypes from 'prop-types';
import {
  MdRadioButtonChecked,
  MdRadioButtonUnchecked,
  MdEdit,
} from 'react-icons/md';
import { Container, ContainerUsers } from './styles';

export default function GridUsers({ users, moreDetailsUser, id }) {
  return (
    <Container>
      {users.length === 0 ? (
        <strong>Não tem nenhum usuário</strong>
      ) : (
        <ContainerUsers id={id}>
          <thead>
            <tr>
              <th>Nome Usuário</th>
              <th>Permissão</th>
              <th>Inativo</th>
              <th>Opções</th>
            </tr>
          </thead>
          <tbody>
            {users.map(element => (
              <tr key={element.id}>
                <td>
                  <strong>{element.username}</strong>
                </td>
                <td>
                  <strong>{element.permission}</strong>
                </td>
                <td>
                  <strong>
                    {element.status === false ? (
                      <MdRadioButtonUnchecked size={22} />
                    ) : (
                      <MdRadioButtonChecked size={22} />
                    )}
                  </strong>
                </td>
                <td>
                  <strong>
                    <MdEdit
                      size={22}
                      onClick={() => {
                        moreDetailsUser(element);
                      }}
                    />
                  </strong>
                </td>
              </tr>
            ))}
          </tbody>
        </ContainerUsers>
      )}
    </Container>
  );
}
GridUsers.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object),
  id: PropTypes.string,
  moreDetailsUser: PropTypes.func,
};
GridUsers.defaultProps = {
  users: {},
  id: 'table-to-xls',
  moreDetailsUser: () => {},
};
