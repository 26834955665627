export function handleUsers() {
  return {
    type: '@users/HANDLE_USERS',
  };
}
export function backHome() {
  return {
    type: '@users/BACK_HOME',
  };
}
export function requestUsers(page) {
  return {
    type: '@users/REQUEST_USERS',
    payload: { page },
  };
}
export function requestUsersFilter(user) {
  return {
    type: '@users/REQUEST_USERS_FILTER',
    payload: { user },
  };
}
export function requestUsersSucess(data) {
  return {
    type: '@users/REQUEST_USERS_SUCESS',
    payload: { data },
  };
}
export function savePages(page, lastPage, total) {
  return {
    type: '@users/SAVE_PAGES',
    payload: { page, lastPage, total },
  };
}
export function deleteUser(id) {
  return {
    type: '@users/DELETE_USER',
    payload: { id },
  };
}
export function openModalUser() {
  return {
    type: '@users/OPEN_MODAL_USER',
  };
}
export function closeModalUser() {
  return {
    type: '@users/CLOSE_MODAL_USER',
  };
}
export function openModalCreateUser() {
  return {
    type: '@users/OPEN_MODAL_CREATE_USER',
  };
}
export function closeModalCreateUser() {
  return {
    type: '@users/CLOSE_MODAL_CREATE_USER',
  };
}
export function requestTablePrice() {
  return {
    type: '@users/REQUEST_TABLE_PRICE',
  };
}
export function requestTablePriceSucess(data) {
  return {
    type: '@users/REQUEST_TABLE_PRICE_SUCESS',
    payload: { data },
  };
}
export function requestUpdateUser(
  username,
  permission,
  status,
  representante_cod,
  password,
  email,
  tablePrice,
  user
) {
  return {
    type: '@users/REQUEST_UPDATE_USER',
    payload: {
      username,
      permission,
      status,
      representante_cod,
      password,
      email,
      tablePrice,
      user,
    },
  };
}
export function createUser(
  username,
  permission,
  status,
  representante_cod,
  password,
  email,
  tablePrice
) {
  return {
    type: '@users/CREATE_USER',
    payload: {
      username,
      permission,
      status,
      representante_cod,
      password,
      email,
      tablePrice,
    },
  };
}
export function cleanAplicationUsers() {
  return {
    type: '@users/CLEAN_APLICATION_USERS',
  };
}
