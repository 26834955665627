import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import {
  requestDuplicatesSucess,
  savePage,
  savePriceTotalDuplicates,
  requestDuplicatePrintSucess,
} from './actions';
import { failureAction, loading, successAction } from '../common/actions';
import history from '../../../services/history';
import { convertDateWithDash, formatPrice } from '../../../utils/format';

function* handlePageDuplicateSaga() {
  yield put(loading());
  history.push('/sistema/duplicate');
  yield put(successAction());
}

function* requestDuplicatesSaga({ payload }) {
  let token = localStorage.getItem('NovaDublagem@Web_Token');
  token = JSON.parse(token);
  yield put(loading());
  const { page } = payload;
  try {
    const { data } = yield call(
      api.get,

      `/duplicata?&page=${page}&pageSize=8`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const response = yield call(
      api.get,
      `/duplicata?&page=1&pageSize=${data.total}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    yield put(savePriceTotalDuplicates(formatPrice(response.data.valorTotal)));
    yield put(requestDuplicatesSucess(data.data));
    yield put(savePage(data.total, data.page, data.lastPage));
    yield put(successAction());
  } catch (err) {
    const menssage = 'Erro ao buscar as duplicatas';
    toast.error(menssage);
    yield put(failureAction(menssage));
  }
}

function* requestDuplicateNumber({ payload }) {
  let token = localStorage.getItem('NovaDublagem@Web_Token');
  token = JSON.parse(token);
  yield put(loading());
  const { input } = payload;

  try {
    const { data } = yield call(
      api.get,
      `/duplicata?filter=nro_duplicata~contains~'${input}'&page=1&pageSize=8`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    yield put(requestDuplicatesSucess(data.data));
    yield put(savePage(data.total, data.page, data.lastPage));
    yield put(successAction());
  } catch (err) {
    const menssage = 'Erro ao filtar duplicatas pelo seu número';
    toast.error(menssage);
    yield put(failureAction(menssage));
  }
}

function* requestDuplicatesDateSaga({ payload }) {
  yield put(loading());
  let token = localStorage.getItem('NovaDublagem@Web_Token');
  token = JSON.parse(token);
  const { dateInitial, dateEnd } = payload;
  const data1 = convertDateWithDash(dateInitial);
  const data2 = convertDateWithDash(dateEnd);
  try {
    const { data } = yield call(
      api.get,
      `/duplicata?filter=(emissao_duplicata~gte~'${data1}'~and~emissao_duplicata~lte~'${data2}')&page=1&pageSize=8`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    yield put(requestDuplicatesSucess(data.data));
    yield put(savePage(data.total, data.page, data.lastPage));
    yield put(successAction());
  } catch (err) {
    const menssage = 'Erro ao filtar duplicatas pela data';
    toast.error(menssage);
    yield put(failureAction(menssage));
  }
}

function* requestDuplicatesPrintSaga({ payload }) {
  yield put(loading());
  let token = localStorage.getItem('NovaDublagem@Web_Token');
  token = JSON.parse(token);

  const { idDuplicate } = payload;
  try {
    const { data } = yield call(api.get, `/boleto/${idDuplicate}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    history.push(`https://backnovadublagem.herokuapp.com/boleto/6073`);

    yield put(requestDuplicatePrintSucess(data));
    yield put(successAction());
  } catch (err) {
    const menssage = 'Erro na impressão do boleto';
    toast.error(menssage);
    yield put(failureAction(menssage));
  }
}

export default all([
  takeLatest('@duplicate/HANDLE_PAGE_DUPLICATE', handlePageDuplicateSaga),
  takeLatest('@duplicate/REQUEST_DUPLICATES', requestDuplicatesSaga),
  takeLatest(
    '@duplicate/REQUEST_DUPLICATES_FOR_DATE',
    requestDuplicatesDateSaga
  ),
  takeLatest(
    '@duplicate/REQUEST_DUPLICATES_FOR_NUMBER',
    requestDuplicateNumber
  ),
  takeLatest('@duplicate/REQUEST_DUPLICATE_PRINT', requestDuplicatesPrintSaga),
]);
