import styled from 'styled-components';

export const Container = styled.div`
  /* largura maxima */
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 630px;
  border-radius: 4px;
  box-shadow: 1px 0 10px rgba(0, 0, 0, 0.1);
  padding: 0 0 0 0;
  margin: 10px;
  h1 {
    font-size: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    svg {
      margin-right: 10px;
    }
  }
`;
