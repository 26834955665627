import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import { Container } from './styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

export default function LinearIndeterminate() {
  const classes = useStyles();

  return (
    <Container>
      <div className={classes.root}>
        <LinearProgress />
      </div>
    </Container>
  );
}
