import produce from 'immer';

const INITIAL_STATE = {
  info: [],
};

export default function validatorclient(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@validator/REQUEST_VALIDATION_OF_CLIENT_SUCESS':
      return produce(state, draft => {
        draft.info = action.payload.data;
      });
    default:
      return state;
  }
}
