import styled from 'styled-components';
import { darken } from 'polished';
import { MdRadioButtonUnchecked, MdRadioButtonChecked } from 'react-icons/md';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 200px;
  padding: 10px;
`;
export const AreaIcon = styled.button`
  background: none;
  border: 0;
  display: flex;
  align-items: center;
`;
export const Icon = styled(MdRadioButtonUnchecked).attrs({
  size: 20,
})`
  transition: color 0.2s;
  &:hover {
    color: ${darken(0.03, '#333')};
  }
`;
export const IconSet = styled(MdRadioButtonChecked).attrs({
  size: 20,
})`
  transition: color 0.2s;
  &:hover {
    color: ${darken(0.03, '#333')};
  }
`;

export const AreaText = styled.div`
  padding-left: 10px;
  display: flex;
  align-items: center;
`;

export const Text = styled.strong`
  font-size: 14px;
`;
