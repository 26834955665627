export function exitAplication() {
  return {
    type: '@exit/EXIT_APLICATION',
  };
}
export function cleanAplication() {
  return {
    type: '@exit/CLEAN_APLICATION',
  };
}
