import React from 'react';
import PropTypes from 'prop-types';
import { Container, HeaderGrid } from './styles';

export default function HeaderTotal({ text }) {
  return (
    <Container>
      <HeaderGrid>
        <strong>{text}</strong>
      </HeaderGrid>
    </Container>
  );
}
HeaderGrid.propTypes = {
  text: PropTypes.string,
};
HeaderGrid.defaultProps = {
  text: 'Title',
};
