export function handleBilings() {
  return {
    type: '@note/HANDLE_BILINGS',
  };
}
export function requestBillings(page, input) {
  return {
    type: '@note/REQUEST_BILLINGS',
    payload: { page, input },
  };
}
export function requestBillingsSucess(billings) {
  return {
    type: '@note/REQUEST_BILLINGS_SUCESS',
    payload: { billings },
  };
}
export function savePage(total, page, lastPage) {
  return {
    type: '@note/SAVE_PAGE',
    payload: { total, page, lastPage },
  };
}
export function requestBillingsForDate(page, pageSize, initialDate, finalDate) {
  return {
    type: '@note/REQUEST_BILLINGS_FOR_DATE',
    payload: { page, pageSize, initialDate, finalDate },
  };
}
export function requestDetailsBilling(id) {
  return {
    type: '@note/REQUEST_DETAILS_BILLING',
    payload: { id },
  };
}
export function requestDetailsBillingSucess(data) {
  return {
    type: '@note/REQUEST_DETAILS_BILLING_SUCESS',
    payload: { data },
  };
}
export function openModalDetailsBilling(state) {
  return {
    type: '@note/OPEN_MODAL_DETAILS_BILLING',
    payload: { state },
  };
}
