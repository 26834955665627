import React from 'react';
import { MdSearch, MdClose } from 'react-icons/md';
import PropTypes from 'prop-types';
import Picker from '../Picker';

import { Container, ButtonEmission } from './styles';

export default function SearchByDate({
  handleDateChange,
  valueInitial,
  handleDateChange2,
  valueEnd,
  searchByDate,
  removeFilterDate,
}) {
  return (
    <Container>
      <Picker
        handleDateChange={date => {
          handleDateChange(date);
        }}
        value={valueInitial.toString()}
        label="Data inicial"
      />
      <Picker
        label="Data Final"
        handleDateChange={date => {
          handleDateChange2(date);
        }}
        value={valueEnd.toString()}
      />
      <ButtonEmission
        type="button"
        onClick={() => {
          searchByDate();
        }}
      >
        <MdSearch />
      </ButtonEmission>
      <ButtonEmission
        type="button"
        onClick={() => {
          removeFilterDate();
        }}
      >
        <MdClose />
      </ButtonEmission>
    </Container>
  );
}
SearchByDate.propTypes = {
  valueInitial: PropTypes.objectOf(PropTypes.string),
  valueEnd: PropTypes.objectOf(PropTypes.string),
  handleDateChange: PropTypes.func,
  handleDateChange2: PropTypes.func,
  removeFilterDate: PropTypes.func,
  searchByDate: PropTypes.func,
};
SearchByDate.defaultProps = {
  valueInitial: new Date(),
  valueEnd: new Date(),
  handleDateChange: () => {},
  handleDateChange2: () => {},
  removeFilterDate: () => {},
  searchByDate: () => {},
};
