export function handleParams() {
  return {
    type: '@params/HANDLE_PARAMS',
  };
}
export function backHome() {
  return {
    type: '@params/BACK_HOME',
  };
}
export function requestParams(page) {
  return {
    type: '@params/REQUEST_PARAMS',
    payload: { page },
  };
}
export function requestParamsSucess(data) {
  return {
    type: '@params/REQUEST_PARAMS_SUCESS',
    payload: { data },
  };
}
export function savePageParams(total, page, lastPage) {
  return {
    type: '@params/SAVE_PAGE',
    payload: { total, page, lastPage },
  };
}
export function requestCarrier() {
  return {
    type: '@params/REQUEST_CARRIER',
  };
}
export function requestCarrierSucess(carries) {
  return {
    type: '@params/REQUEST_CARRIER_SUCESS',
    payload: { carries },
  };
}
export function requestUpdateParams(
  id,
  dias_cliente_inativo,
  dias_data_faturamento,
  trasportadora_padrao
) {
  return {
    type: '@params/REQUEST_UPDATE_PARAMS',
    payload: {
      id,
      dias_cliente_inativo,
      dias_data_faturamento,
      trasportadora_padrao,
    },
  };
}
