import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import PropTypes from 'prop-types';
import { MdClose, MdDelete, MdAdd } from 'react-icons/md';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import Radius from '../Radius';

// import SelectPermission from '../SelectPermission';
import * as UsersActions from '../../store/modules/users/actions';
import {
  Container,
  ContainerInfo,
  ContainerOne,
  ContainerTwo,
  HeaderModal,
  Input,
  ContainerBody,
  Tables,
  Text,
  Footer,
  ContainerButtonTotal,
  ContainerButton,
  Button,
  TextBold,
  ContainerSelect,
  Select,
  SelectPermission,
} from './styles';

export default function ModalUsers({ modalVisible, handleClose, tables }) {
  const dispatch = useDispatch();
  const [radius, setRadius] = useState(true);
  const [itens, setItens] = useState([]);
  const [dataOptions, setDataOptions] = useState([]);
  const [nameUser, setNameUser] = useState(null);
  const [codUser, setCodUSer] = useState(null);
  const [password, setPassword] = useState(null);
  const [email, setEmail] = useState(null);
  const [selectTable, setSelectTable] = useState(null);
  const [status, setStatus] = useState(false);
  const [dataPermission, setDataPermission] = useState(null);
  const [permission, setPermission] = useState([ // eslint-disable-line
    {
      permissao: 'Escolha a permissão',
    },
    {
      id: 1,
      permissao: 'Administrador',
    },
    {
      id: 2,
      permissao: 'Cliente',
    },
    {
      id: 3,
      permissao: 'Representante',
    },
    {
      id: 4,
      permissao: 'Televenda',
    },
  ]);
  useEffect(() => {
    if (tables.length !== 0) {
      setDataOptions(tables);
    }
  }, [tables]);
  const useStyles = makeStyles(theme => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '0',
      borderRadius: '8px',
      boxShadow: theme.shadows[5],
    },
  }));
  const classes = useStyles();
  function deleteTableList(index) {
    const newItens = itens.filter((table, indexTable) => { // eslint-disable-line
      if (indexTable !== index) {
        return table;
      }
    });
    setItens(newItens);
    toast.info('Tabela removida');
  }
  const clickRadius = () => {
    setRadius(!radius);
    setStatus(!status);
  };

  function addTablePrice() {
    if (selectTable !== null) {
      const tableExist = itens.findIndex(t => {
        return t.id === Number(selectTable);
      });
      if (tableExist !== -1) {
        toast.error('Tabela já existente para este usuário');
      } else {
        const tabela = tables.find(element => {
          return element.id === Number(selectTable);
        });
        setItens([...itens, tabela]);
      }
    }
  }
  const createUser = () => {
    if (
      nameUser !== null &&
      codUser !== null &&
      password !== null &&
      dataPermission !== null &&
      dataPermission !== 'Escolha a permissão'
    ) {
      dispatch(
        UsersActions.createUser(
          nameUser,
          dataPermission,
          status,
          codUser,
          password,
          email,
          itens
        )
      );
      setCodUSer(null);
      setEmail(null);
      setNameUser(null);
      setPassword(null);
      setStatus(false);
      setDataPermission(null);
      setItens([]);
    } else {
      toast.error('Verifique os campos para depois criar o usuário');
    }
  };
  // console.tron.log('dataPermission');
  // console.tron.log(dataPermission);
  return (
    <Container>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modalVisible}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalVisible}>
          <div className={classes.paper}>
            <HeaderModal>
              <div>
                {' '}
                <strong>Cadastro de usuários</strong>
              </div>
              <button
                type="button"
                onClick={() => {
                  handleClose();
                }}
              >
                <MdClose size={25} />
              </button>
            </HeaderModal>

            <ContainerInfo>
              <ContainerOne>
                <ContainerBody>
                  <Text>Nome do usuário</Text>
                  <Input
                    placeholder="Nome do usuário"
                    value={nameUser}
                    onChange={text => {
                      setNameUser(text.target.value);
                    }}
                  />
                  <Text>E-mail</Text>
                  <Input
                    type="email"
                    placeholder="E-mail"
                    value={email}
                    onChange={text => {
                      setEmail(text.target.value);
                    }}
                  />
                  <Text>Código do representante</Text>
                  <Input
                    placeholder="Código do representante"
                    value={codUser}
                    onChange={text => {
                      setCodUSer(text.target.value);
                    }}
                  />
                  <Text>Permissão</Text>
                  <SelectPermission
                    onClick={e => {
                      setDataPermission(e.target.value);
                    }}
                  >
                    {permission.map(element => (
                      <>
                        <option value={element.permissao}>
                          {element.permissao}
                        </option>
                      </>
                    ))}
                  </SelectPermission>

                  <Text>Senha</Text>
                  <Input
                    type="password"
                    placeholder="Senha"
                    value={password}
                    onChange={text => {
                      setPassword(text.target.value);
                    }}
                  />
                  <Text>Selecine a tabela de preço</Text>
                  <ContainerSelect onSubmit={e => addTablePrice(e)}>
                    <Select
                      name="form_table"
                      onClick={e => {
                        setSelectTable(e.target.value);
                      }}
                    >
                      {dataOptions.map(table => (
                        <option value={table.id}>{table.tabelapreco}</option>
                      ))}
                    </Select>
                    <button
                      type="button"
                      onClick={() => {
                        addTablePrice();
                      }}
                    >
                      <MdAdd size={20} color="#fff" />
                    </button>
                  </ContainerSelect>

                  <Radius
                    clickIcon={radius}
                    functionOnClikIcon={() => {
                      clickRadius();
                    }}
                    text="Usuário Inativo"
                  />
                </ContainerBody>
              </ContainerOne>

              <Tables>
                <ContainerTwo>
                  <thead>
                    <tr>
                      <th>Tabela de preço</th>
                      <th>Editar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {itens.map((name, index) => (
                      <tr>
                        <td>
                          <strong>{name.tabelapreco}</strong>
                        </td>
                        <div>
                          <button
                            type="button"
                            onClick={() => {
                              deleteTableList(index);
                            }}
                          >
                            <MdDelete size={22} />
                          </button>
                        </div>
                      </tr>
                    ))}
                  </tbody>
                </ContainerTwo>
              </Tables>
            </ContainerInfo>
            <Footer>
              <ContainerButtonTotal>
                <ContainerButton>
                  <Button
                    type="button"
                    onClick={() => {
                      createUser();
                    }}
                  >
                    <TextBold>CRIAR</TextBold>
                  </Button>
                </ContainerButton>
              </ContainerButtonTotal>
            </Footer>
          </div>
        </Fade>
      </Modal>
    </Container>
  );
}
ModalUsers.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  tables: PropTypes.arrayOf(PropTypes.object),
  modalVisible: PropTypes.bool,
  handleClose: PropTypes.func,
};
ModalUsers.defaultProps = {
  data: {},
  tables: {},
  modalVisible: false,
  handleClose: () => {},
};
