import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Logo from '../../assets/images/logoWeb.png';
import Button from '../../components/Button';
import * as ValidatorActions from '../../store/modules/validatorclient/actions';
import {
  Container,
  AreaImage,
  Image,
  Title,
  Text,
  ContainerInput,
  Input,
} from './styles';

export default function RegisterClient() {
  const dispatch = useDispatch();
  const [inputUsername, setInputUsername] = useState(null);
  const [inputPassword, setInputPassword] = useState(null);
  function createUserClient() {
    if (inputUsername !== null && inputPassword !== null) {
      dispatch(
        ValidatorActions.requestCreateUserClient(inputUsername, inputPassword)
      );
    }
  }
  return (
    <Container>
      <AreaImage>
        <Image src={Logo} alt="novaDublagem" />
      </AreaImage>
      <Title>Registrador</Title>
      <Text>Digite as infromações abaixo para finalizar o cadastro.</Text>
      <ContainerInput>
        <Input
          id="input-with-icon-textfield"
          label="E-mail"
          value={inputUsername}
          onChange={text => {
            setInputUsername(text.target.value);
          }}
        />
      </ContainerInput>
      <ContainerInput>
        <Input
          id="input-with-icon-textfield"
          label="Senha"
          value={inputPassword}
          onChange={text => {
            setInputPassword(text.target.value);
          }}
          type="password"
        />
      </ContainerInput>
      <Button
        titleButton="CONFIRMAR"
        functionOnClick={() => {
          createUserClient();
        }}
      />
    </Container>
  );
}
