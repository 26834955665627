import styled from 'styled-components';
// import Select from 'react-select';
import { colors } from '../../styles';

export const Container = styled.div`
  /* largura maxima */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  height: 630px;
  border-radius: 4px;
  box-shadow: 1px 0 10px rgba(0, 0, 0, 0.1);
  padding: 0 0 0 0;
  margin: 10px;
  h1 {
    font-size: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    svg {
      margin-right: 10px;
    }
  }
`;
export const HeaderTable = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  align-items: center;
  padding-left: 20px;
`;
export const ContainerButtonTotal = styled.div`
  display: flex;
  flex-direction: row;
`;
export const ContainerButton = styled.div`
  padding: 2px;
  button {
    border: 0;
    background: ${colors.secundary};
    width: 60px;
    padding: 10px;
    border-radius: 8px;
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
`;
export const ButtonExcel = styled.strong``;

export const ParamsTable = styled.table`
  width: 100%;
  padding: 20px;
  thead th {
    color: #999;
    text-align: left;
    padding-bottom: 20px;
    border: 0;
  }
  thead td {
    text-align: left;
  }
  tbody td {
    text-align: left;
    padding-left: 1px;
  }
  /* tbody tr {
  } */

  strong {
    color: #333;
    padding: 10px;
    display: block;
    /* padding-bottom: 25px; */
  }

  div {
    display: flex;
    align-items: center;
    button {
      height: 40px;
      border-radius: 8px;
      background: ${colors.primary};
      border: 0px;
      margin-bottom: 10px;
      > strong {
        color: #333;
        display: block;
      }
    }
  }
`;
export const ContainerDiv = styled.div``;

export const Selects = styled.select`
  width: 250px;
  height: 40px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  &:focus {
    border-color: ${colors.primary};
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`;
export const InputEdit = styled.input.attrs({
  size: 10,
})`
  width: 250px;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #666;
  padding: 10px;
  height: 40px;
  &:focus {
    border-color: ${colors.primary};
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  option {
    height: 100px;
  }
`;

export const Options = styled.option``;
