import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';

import history from '../../../services/history';
import { failureAction, loading, successAction } from '../common/actions';
import {
  closeModalCreateUser,
  closeModalUser,
  requestTablePriceSucess,
  requestUsers,
  requestUsersSucess,
  savePages,
} from './actions';
// import { UserException, errorVerify } from '../../../config/Exceptions';

let token = localStorage.getItem('NovaDublagem@Web_Token');
token = JSON.parse(token);

function* handleUsers() {
  yield put(loading());
  history.push('/sistema/home/Users');
  yield put(successAction());
}
function* backHome() {
  yield put(loading());
  history.push('/sistema/home');
  yield put(successAction());
}
function* requestUsersSaga(action) {
  yield put(loading());
  const { page } = action.payload;
  try {
    const { data } = yield call(api.get, `/users?&page=${page}&pageSize=7`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    yield put(requestUsersSucess(data.data));
    yield put(savePages(data.page, data.lastPage, data.total));
    yield put(successAction());
  } catch (err) {
    const menssage = 'Erro ao buscar os usuários';
    toast.error(menssage);
    yield put(failureAction(menssage));
  }
}
function* deleteUser(action) {
  yield put(loading());
  try {
    const { id } = action.payload;
    const response = yield call(api.delete, `/users/${id}`, { // eslint-disable-line
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const { data } = yield call(api.get, `/users?&page=1&pageSize=7`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put(requestUsersSucess(data.data));
    yield put(savePages(data.page, data.lastPage, data.total));
    yield put(successAction());
  } catch (err) {
    const menssage = 'Erro ao excluir o usuário';
    toast.error(menssage);
    yield put(failureAction(menssage));
  }
}
function* searchTablePrice() {
  yield put(loading());
  try {
    const { data } = yield call(api.get, `/tabelapreco`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put(requestTablePriceSucess(data));
    yield put(successAction());
  } catch (err) {
    const menssage = 'Erro ao buscar a tabela de preço';
    toast.error(menssage);
    yield put(failureAction(menssage));
  }
}

function* requestUsersFilter(action) {
  yield put(loading());
  try {
    const { user } = action.payload;
    const response = yield call(
      api.get,
      `/users?filter=username~contains~'${user}'&page=1&pageSize=7`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    yield put(requestUsersSucess(response.data.data));
    yield put(
      savePages(response.data.page, response.data.lastPage, response.data.total)
    );
    yield put(successAction());
  } catch (err) {
    const menssage = 'Erro ao buscar o usuário';
    toast.error(menssage);
    yield put(failureAction(menssage));
  }
}

function* createUserSaga(action) {
  yield put(loading());
  try {
    const response = yield call(api.post, `/users`, action.payload, { // eslint-disable-line
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put(requestUsers(1));
    yield put(closeModalCreateUser());
    toast.success('Usuário criado com sucesso');
  } catch (err) {
    const menssage = 'Erro ao criar usuário';
    toast.error(menssage);
    yield put(failureAction(menssage));
  }
}
function* updateUserSaga(action) {
  yield put(loading());
  const {
    username,
    permission,
    status,
    representante_cod, // eslint-disable-line
    password,
    email,
    tablePrice,
    user,
  } = action.payload;
  const idUser = user.id;
  const data = {
    username,
    permission,
    status,
    representante_cod,
    password,
    email,
    tablePrice,
  };

  try {
    const response = yield call(api.put, `/users/${idUser}`, data, { // eslint-disable-line
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put(requestUsers(1));
    yield put(closeModalUser());
    toast.success('Usuário atualizado com sucesso');
  } catch (err) {
    // console.tron.log('err');
    // console.tron.log(err);
    const menssage = 'Erro ao atualizar o usuário';
    toast.error(menssage);
    yield put(failureAction(menssage));
  }
}

export default all([
  takeLatest('@users/HANDLE_USERS', handleUsers),
  takeLatest('@users/BACK_HOME', backHome),
  takeLatest('@users/REQUEST_USERS', requestUsersSaga),
  takeLatest('@users/REQUEST_USERS_FILTER', requestUsersFilter),
  takeLatest('@users/DELETE_USER', deleteUser),
  takeLatest('@users/REQUEST_TABLE_PRICE', searchTablePrice),
  takeLatest('@users/CREATE_USER', createUserSaga),
  takeLatest('@users/REQUEST_UPDATE_USER', updateUserSaga),
]);
