import styled from 'styled-components';

export const Container = styled.div``;

export const ContainerUsers = styled.table`
  width: 100%;
  padding: 15px;

  thead th {
    color: #999;
    text-align: left;
    padding-bottom: 30px;
    border: 0;
  }
  thead td {
    text-align: left;
  }
  tbody td {
    width: 250px;
    text-align: left;
    padding-left: 1px;
  }
  tbody tr {
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }

  strong {
    color: #333;
    display: block;
    padding-bottom: 25px;
  }
`;
