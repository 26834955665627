import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import { requestOrdersSucess, savePage } from './actions';
import history from '../../../services/history';
import { convertDateWithDash } from '../../../utils/format';
import { failureAction, loading, successAction } from '../common/actions';

// import { UserException, errorVerify } from '../../../config/Exceptions';

function* handleOrders() {
  yield put(loading());
  history.push('/sistema/home');
  yield put(successAction());
}

function* backHome() {
  yield put(loading());
  history.push('/sistema/home');
  yield put(successAction());
}
function* requestOrder(action) {
  yield put(loading());
  let token = localStorage.getItem('NovaDublagem@Web_Token');
  token = JSON.parse(token);
  const { page } = action.payload;
  try {
    const { data } = yield call(api.get, `/pedido?&page=${page}&pageSize=8`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    yield put(savePage(data.total, data.page, data.lastPage));
    yield put(requestOrdersSucess(data.data));
    yield put(successAction());
  } catch (err) {
    const menssage = 'Erro ao buscar os pedidos';
    toast.error(menssage);
    yield put(failureAction(menssage));
  }
}

function* requestOrderForId(action) {
  yield put(loading());
  let token = localStorage.getItem('NovaDublagem@Web_Token');
  token = JSON.parse(token);
  const { inputCod } = action.payload;
  try {
    const { data } = yield call(
      api.get,
      `/pedido?filter=id~contains~'${inputCod}'&page=1&pageSize=8`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    yield put(requestOrdersSucess(data.data));
    yield put(savePage(data.total, data.page, data.lastPage));
    yield put(successAction());
  } catch (err) {
    const menssage = 'Erro ao filtrar os pedidos';
    toast.error(menssage);
    yield put(failureAction(menssage));
  }
}

function* requestOrderForEmission(action) {
  yield put(loading());
  let token = localStorage.getItem('NovaDublagem@Web_Token');
  token = JSON.parse(token);
  const { inputEmission1, inputEmission2 } = action.payload;
  const data1 = convertDateWithDash(inputEmission1);
  const data2 = convertDateWithDash(inputEmission2);

  try {
    const { data } = yield call(
      api.get,
      `/pedido?filter=(emissao~gte~'${data1}'~and~emissao~lte~'${data2}')&page=1&pageSize=10`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    yield put(requestOrdersSucess(data.data));
    yield put(savePage(data.total, data.page, data.lastPage));
    yield put(successAction());
  } catch (err) {
    const menssage = 'Erro ao filtrar os pedidos';
    toast.error(menssage);
    yield put(failureAction(menssage));
  }
}

export default all([
  takeLatest('@orders/HANDLE_ORDERS', handleOrders),
  takeLatest('@orders/BACK_HOME', backHome),
  takeLatest('@orders/REQUEST_ORDERS', requestOrder),
  takeLatest('@orders/REQUEST_ORDERS_FOR_ID', requestOrderForId),
  takeLatest('@orders/REQUEST_ORDERS_FOR_EMISSION', requestOrderForEmission),
]);
