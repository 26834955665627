import { all, put, takeLatest } from 'redux-saga/effects';

import history from '../../../services/history';
import {
  loading,
  successAction,
  // breakAction,
} from '../common/actions';
import { cleanAplicationHome } from '../home/actions';
import { cleanAplicationDuplicates } from '../duplicate/actions';
import { cleanAplicationOrders } from '../orders/actions';
// import { UserException, errorVerify } from '../../../config/Exceptions';

function* exitAplication() {
  yield put(loading());
  localStorage.removeItem('NovaDublagem@Web');
  localStorage.removeItem('NovaDublagem@Web_Token');
  localStorage.removeItem('NovaDublagem@Web_RegisterClient');
  localStorage.removeItem('NovaDublagem@Web_Cnpj');
  yield put(cleanAplicationHome());

  yield put(cleanAplicationDuplicates());
  yield put(cleanAplicationOrders());
  yield put(successAction());
  history.push('/');
}

export default all([takeLatest('@exit/EXIT_APLICATION', exitAplication)]);
