import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import {
  savePage,
  requestBillingsSucess,
  openModalDetailsBilling,
  requestDetailsBillingSucess,
} from './actions';
import { failureAction, loading, successAction } from '../common/actions';
import history from '../../../services/history';
import {
  convertDateApiString,
  convertDateWithDash,
} from '../../../utils/format';

function* handleBilings() {
  yield put(loading());
  history.push('/sistema/billing');
  yield put(successAction());
}
function* requestBillingSaga({ payload }) {
  yield put(loading());
  let token = localStorage.getItem('NovaDublagem@Web_Token');
  token = JSON.parse(token);
  const { page, input } = payload;

  try {
    const { data } = yield call(
      api.get,

      `/allnotas?page=${page}&pageSize=8&nomeRazao=${input}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { data: billings } = data;

    const newBillings = [];
    // const compare = [];
    for (let i = 0; i < billings.length; i += 1) {
      const {
        id,
        emissao,
        nota,
        nota: notaTransfom,
        descricao,
        nome_razao,
      } = billings[i];

      const order2 = {
        id,
        nota,
        emissao: convertDateApiString(emissao),
        descricao,
        notatransformad: String(notaTransfom),
        nome_razao,
      };
      newBillings.push(order2);
    }
    yield put(requestBillingsSucess(newBillings));
    yield put(savePage(data.total, data.page, data.lastPage));
    yield put(successAction());
  } catch (err) {
    const menssage = 'Erro ao buscar as duplicatas';
    toast.error(menssage);
    yield put(failureAction(menssage));
  }
}

function* requestBillingForDateSaga({ payload }) {
  yield put(loading());
  let token = localStorage.getItem('NovaDublagem@Web_Token');
  token = JSON.parse(token);

  const { page, pageSize, initialDate, finalDate } = payload;
  const date1 = convertDateWithDash(initialDate);
  const date2 = convertDateWithDash(finalDate);
  try {
    const { data } = yield call(
      api.get,

      `/allnotas?page=${page}&pageSize=${pageSize}&initialDate=${date1}&finalDate=${date2}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { data: billings } = data;

    const newBillings = [];
    // const compare = [];
    for (let i = 0; i < billings.length; i += 1) {
      const {
        id,
        emissao,
        nota,
        nota: notaTransfom,
        descricao,
        nome_razao,
      } = billings[i];

      const order2 = {
        id,
        nota,
        emissao: convertDateApiString(emissao),
        descricao,
        notatransformad: String(notaTransfom),
        nome_razao,
      };
      newBillings.push(order2);
    }
    yield put(requestBillingsSucess(newBillings));
    yield put(savePage(data.total, data.page, data.lastPage));
    yield put(successAction());
  } catch (err) {
    const menssage = 'Erro ao buscar as duplicatas';
    toast.error(menssage);
    yield put(failureAction(menssage));
  }
}

function* requestDetailsBilling({ payload }) {
  yield put(loading());
  let token = localStorage.getItem('NovaDublagem@Web_Token');
  token = JSON.parse(token);
  const { id } = payload;
  try {
    const { data } = yield call(api.get, `/notaitens?notaid=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    yield put(requestDetailsBillingSucess(data[0]));
    yield put(successAction());
    yield put(openModalDetailsBilling(true));
  } catch (err) {
    const menssage = 'Erro ao buscar detalhes da nota';
    toast.error(menssage);
    yield put(failureAction(menssage));
  }
}

export default all([
  takeLatest('@note/HANDLE_BILINGS', handleBilings),
  takeLatest('@note/REQUEST_BILLINGS', requestBillingSaga),
  takeLatest('@note/REQUEST_BILLINGS_FOR_DATE', requestBillingForDateSaga),
  takeLatest('@note/REQUEST_DETAILS_BILLING', requestDetailsBilling),
]);
