import produce from 'immer';

const INITIAL_STATE = {
  billings: [],
  total: '0',
  page: 1,
  lastPage: 0,
  modalDetails: false,
  detailsNote: [],
};

export default function login(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@note/REQUEST_BILLINGS_SUCESS':
      return produce(state, draft => {
        draft.billings = action.payload.billings;
      });
    case '@note/SAVE_PAGE':
      return produce(state, draft => {
        draft.total = action.payload.total;
        draft.page = action.payload.page;
        draft.lastPage = action.payload.lastPage;
      });
    case '@note/CLEAN_APLICATION_BILLINGS':
      return produce(state, draft => {
        draft.duplicates = [];
        draft.page = 1;
        draft.lastPage = 0;
        draft.total = '0';
        draft.priceTotal = null;
      });
    case '@note/REQUEST_DETAILS_BILLING_SUCESS':
      return produce(state, draft => {
        draft.detailsNote = action.payload.data;
      });
    case '@note/OPEN_MODAL_DETAILS_BILLING':
      return produce(state, draft => {
        draft.modalDetails = action.payload.state;
      });
    default:
      return state;
  }
}
