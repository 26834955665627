import styled, { css } from 'styled-components';
import { MdAccountCircle } from 'react-icons/md';
import { colors } from '../../styles';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 50px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #707070;
  padding: 0 16px;

  font-size: 25px;

  ${props =>
    props.isFocused &&
    css`
      color: ${colors.secundary};
      border: 1px solid ${colors.secundary};
    `}
  ${props =>
    props.isFilled &&
    css`
      color: ${colors.secundary};
    `}
    ${props =>
      props.isError &&
      css`
        border: 1px solid #c53030;
      `}
`;

export const AreaInput = styled.div`
  display: flex;
  flex: 6;
`;
export const AreaIcon = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const TextInput = styled.input`
  outline: ${colors.secundary};
  border: ${colors.secundary};
  font-size: 18px;
`;

export const Icon = styled(MdAccountCircle)`
  font-size: 25px;
`;
