export function requestValidationOfClient(cnpj) {
  return {
    type: '@validator/REQUEST_VALIDATION_OF_CLIENT',
    payload: { cnpj },
  };
}
export function requestValidationOfClientSucess(data) {
  return {
    type: '@validator/REQUEST_VALIDATION_OF_CLIENT_SUCESS',
    payload: { data },
  };
}
export function requestCreateUserClient(username, password) {
  return {
    type: '@validator/REQUEST_CREATE_USER_CLIENT',
    payload: { username, password },
  };
}
