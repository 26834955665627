import produce from 'immer';

const INITIAL_STATE = {
  orders: [],
  page: 1,
  total: '0',
  lastPage: 0,
};

export default function login(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@orders/REQUEST_ORDERS_SUCESS':
      return produce(state, draft => {
        draft.orders = action.payload.data;
      });
    case '@orders/SAVE_PAGE':
      return produce(state, draft => {
        draft.page = action.payload.page;
        draft.total = action.payload.total;
        draft.lastPage = action.payload.lastPage;
      });
    case '@orders/CLEAN_APLICATION_ORDERS':
      return produce(state, draft => {
        draft.orders = [];
        draft.page = 1;
        draft.total = '0';
        draft.lastPage = 0;
      });
    default:
      return state;
  }
}
