import styled from 'styled-components';
import { MdPrint } from 'react-icons/md';
import { darken } from 'polished';

export const Container = styled.div``;

export const Duplicates = styled.table`
  width: 100%;
  padding: 18px;
  thead th {
    color: #999;
    text-align: left;
    padding-bottom: 20px;
    border: 0;
  }
  thead td {
    text-align: left;
  }
  tbody td {
    text-align: left;
    padding-left: 1px;
  }
  tbody tr {
    transition: opacity 0.2s;
  }

  strong {
    color: #333;
    margin: 4px 0 0 0;
    display: block;
    padding-bottom: 25px;
  }

  div {
    display: flex;
    align-items: center;

    display: flex;

    input {
      border: 1px solid #ddd;
      border-radius: 4px;
      color: #666;
      padding: 6px;
      width: 50px;
    }
    button {
      background: none;
      margin-top: -16px;
      margin-right: 10px;
      border: 0px;
      padding: 6px;
    }
  }
`;
export const IconPrint = styled(MdPrint)`
  color: #707071;
  &:hover {
    &:hover {
      color: ${darken(0.08, '#707070')};
    }

    cursor: pointer;
  }
`;
