import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import PropTypes from 'prop-types';
import { MdClose } from 'react-icons/md';
import { convertDateApiString } from '../../utils/format';
import {
  Container,
  ContainerInfo,
  TextBold,
  TextNormal,
  Item,
  HeaderModal,
  ContainerBody,
  ContainerValues,
  ContainerItem,
  // DivItens,
} from './styles';

export default function ModalBilling({ modalVisible, handleClose, data }) {
  // const [doubleGrid, setDoubleGrid] = useState(true);

  const useStyles = makeStyles(theme => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '0',
      borderRadius: '8px',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      height: `450px`,
      width: `750px`,
    },
  }));
  const classes = useStyles();
  return (
    <Container>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modalVisible}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalVisible}>
          <div className={classes.paper}>
            {data.length === 0 ? null : (
              <div>
                <HeaderModal>
                  <div>
                    {' '}
                    <strong>Detalhes da nota</strong>
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    <MdClose size={25} />
                  </button>
                </HeaderModal>
                <ContainerBody>
                  <ContainerValues>
                    <TextBold>Valor da nota: 2500,00</TextBold>
                    <TextBold>Total produtos: {data.total_produtos}</TextBold>
                    <TextBold>
                      Total acréscimos: {data.total_acrescimos}
                    </TextBold>
                  </ContainerValues>
                  <ContainerInfo>
                    <TextBold>
                      Nota: <TextNormal>{data.nota}</TextNormal>
                    </TextBold>
                    <TextBold>
                      Cliente: <TextNormal>{data.nome_razao}</TextNormal>
                    </TextBold>
                    <TextBold>
                      Emissão:{' '}
                      <TextNormal>
                        {convertDateApiString(data.emissao)}
                      </TextNormal>
                    </TextBold>
                    <TextBold>
                      Transportadora:{' '}
                      <TextNormal>{data.transportadora}</TextNormal>
                    </TextBold>
                    <TextBold>
                      Condição de pagamento:{' '}
                      <TextNormal>{data.descricao}</TextNormal>
                    </TextBold>
                  </ContainerInfo>
                  <ContainerItem>
                    <TextBold>Itens da nota:</TextBold>
                  </ContainerItem>
                  <Item>
                    <thead>
                      <tr>
                        <th>Pedido industria</th>
                        <th>Quantidade faturada</th>
                        <th>Valor item</th>
                        <th>Valor comissão 1</th>
                        <th>Valor comissão 2</th>
                        <th>Comissão item</th>
                      </tr>
                    </thead>
                    {data.itens_nota.map(element => (
                      <tbody>
                        <tr>
                          <td>
                            <strong>{element.pedido_industria}</strong>
                          </td>
                          <td>
                            <strong>{element.quantidade_faturada}</strong>
                          </td>
                          <td>
                            <strong>{element.valor_item}</strong>
                          </td>
                          <td>
                            <strong>{element.valor_comissao_1}</strong>
                          </td>
                          <td>
                            <strong>{element.valor_comissao_2}</strong>
                          </td>
                          <td>
                            <strong>{element.comissao_item}</strong>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </Item>
                </ContainerBody>
              </div>
            )}
          </div>
        </Fade>
      </Modal>
    </Container>
  );
}
ModalBilling.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  modalVisible: PropTypes.bool,
  handleClose: PropTypes.func,
};
ModalBilling.defaultProps = {
  data: [{}],
  modalVisible: false,
  handleClose: () => {},
};
