import { all, put, takeLatest } from 'redux-saga/effects';
import { successAction } from '../common/actions';

function* requestAddToUserRepository() {
  yield put(successAction());
}
export default all([
  takeLatest(
    '@home/REQUEST_ADD_TO_USER_REPOSITORY',
    requestAddToUserRepository
  ),
]);
