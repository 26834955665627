import styled from 'styled-components';
import { colors } from '../../styles';

export const Container = styled.div`
  /* largura maxima */

  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin: 80px auto;
  h1 {
    font-size: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    svg {
      margin-right: 10px;
    }
  }
`;
export const ContainerTotal = styled.div`
  /* largura maxima */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 4px;

  h1 {
    font-size: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    svg {
      margin-right: 10px;
    }
  }
`;
// const Container = styled.div`
//   /* largura maxima */

//   background: #fff;
//   border-radius: 4px;
//   box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
//   padding: 30px;
//   margin: 80px auto;
//   h1 {
//     font-size: 20px;
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     svg {
//       margin-right: 10px;
//     }
//   }
// `;
export const ContainerButtonTotal = styled.div`
  display: flex;
  flex-direction: row;
`;
export const ContainerButton = styled.div`
  padding: 2px;
  button {
    border: 0;
    background: ${colors.secundary};
    width: 60px;
    padding: 10px;
    border-radius: 8px;
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
`;
export const HeaderTable = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  align-items: center;
  padding-left: 20px;
`;
