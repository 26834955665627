import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { MdMenu } from 'react-icons/md';
import { colors } from '../../styles';

export const Container = styled.header`
  display: flex;
  /* justify-content: space-between;
  align-items: center; */
  justify-content: space-between;
  height: 60px;
  width: 100%;
  background: #3f51b5;
  padding: 10px;

  h1 {
    color: #fff;
    padding-left: 10px;
  }
  img {
    padding-left: 20px;
    height: 39px;
    width: 140px;
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.7;
    }
  }
`;
export const ContainerDrawer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ListMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const ListMenuText = styled.button`
  border-width: 0;
  background: none;
  padding: 15px;
  transition: opacity 0.2s;
  transition: opacity 0.2s;
  &:hover {
    opacity: 0.7;
  }
`;
export const FontTextMenu = styled.strong`
  font-size: bold;
  color: #454545;
  font-size: 16px;
`;

export const InputMenu = styled.input`
  height: 40px;
  width: 100%;
  border: 1px solid #bbb;
  border-radius: 8px;
  padding: 10px;
`;

export const AreaMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const AreaUser = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
`;

export const Avatar = styled.div`
  display: flex;
  height: 46px;
  width: 46px;
  background: ${colors.secundary};
  border-radius: 46px;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s;
  &:hover {
    opacity: 0.7;
  }
`;
export const TextAvatar = styled.strong`
  font-size: 20px;
`;

export const Menu = styled(MdMenu)`
  font-size: 30px;
  transition: opacity 0.2s;
  color: #fff;
  &:hover {
    opacity: 0.7;
  }
`;

export const Cart = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: opacity 0.2s;
  &:hover {
    opacity: 0.7;
  }
  div {
    text-align: right;
    margin-right: 10px;
    strong {
      display: block;
      color: #fff;
    }
    span {
      font-size: 12px;
      color: #999;
    }
  }
`;
export const AreaLogo = styled.div`
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const LogoDrawer = styled.img`
  height: 55px;
`;
