import styled from 'styled-components';

export const Container = styled.div``;

export const HeaderGrid = styled.div`
  display: flex;
  flex: 1;
  height: 40px;
  align-items: center;
  padding-left: 20px;
  > strong {
    color: #3f51b7;
  }
`;
