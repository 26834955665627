import { combineReducers } from 'redux';

import login from './login/reducer';
import home from './home/reducer';
import common from './common/reducer';
import users from './users/reducers';
import params from './params/reducers';
import orders from './orders/reducers';
import validatorclient from './validatorclient/reducers';
import duplicate from './duplicate/reducers';
import note from './note/reducers';

export default combineReducers({
  login,
  home,
  common,
  users,
  params,
  orders,
  validatorclient,
  duplicate,
  note,
});
