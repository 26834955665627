import React, { useEffect, memo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactToExcel from 'react-html-table-to-excel';

import * as jsPDF from 'jspdf';
import Header from '../../components/Header';
import ModalUsers from '../../components/ModalUsers';
import RegisterUser from '../../components/RegisterUser';
import { ContainerTotal } from '../../components/Container';
import HeaderTotal from '../../components/HeaderGrid';
import Footer from '../../components/Footer';
import GridUsers from '../../components/GridUsers';
import InputWithButton from '../../components/InputWithButton';
import LinearProgress from '../../components/Loading';
import 'jspdf-autotable';

import {
  Container,
  HeaderTable,
  ContainerButtonTotal,
  ContainerButton,
  // ButtonExcel,
} from './styles';

import * as ActionsUsers from '../../store/modules/users/actions';

function Users() {
  const dispatch = useDispatch();
  const {
    users,
    page,
    lastPage,
    total,
    modalUser,
    tables,
    modalCreate,
  } = useSelector(state => state.users);
  const { loading } = useSelector(state => state.common);
  const [inputUser, setInputUser] = useState('');
  const [user, setUser] = useState([]);
  useEffect(() => {
    dispatch(ActionsUsers.requestTablePrice());
  }, []);// eslint-disable-line
  useEffect(() => {
    dispatch(ActionsUsers.requestUsers(1));
  }, []);// eslint-disable-line
  function paginateOrders() {
    if (lastPage > page) {
      const newPage = page + 1;
      dispatch(ActionsUsers.requestUsers(newPage));
    } else {
      dispatch(ActionsUsers.requestUsers(page));
    }
  }
  function initialPage() {
    dispatch(ActionsUsers.requestUsers(1));
  }
  function lastPageOrder() {
    dispatch(ActionsUsers.requestUsers(lastPage));
  }
  function backPageOrder() {
    if (lastPage >= page) {
      if (page >= 2) {
        const newPage = page - 1;
        dispatch(ActionsUsers.requestUsers(newPage));
      }
    } else {
      dispatch(ActionsUsers.requestUsers(page));
    }
  }
  function moreDetailsUser(element) {
    dispatch(ActionsUsers.openModalUser());
    setUser(element);
  }
  const handleClose = () => {
    dispatch(ActionsUsers.closeModalUser());
    setUser([]);
  };
  const searchUser = () => {
    if (inputUser !== '') {
      dispatch(ActionsUsers.requestUsersFilter(inputUser));
    } else {
      dispatch(ActionsUsers.requestUsers(1));
    }
  };
  const removeFilter = () => {
    dispatch(ActionsUsers.requestUsers(1));
    setInputUser('');
  };

  const geraPdf = () => {
    const doc = new jsPDF('p', 'pt');// eslint-disable-line
    doc.autoTable({
      body: users,
      columns: [
        { header: 'Nome', dataKey: 'username' },
        { header: 'Permissão', dataKey: 'permission' },
        { header: 'Inativo' },
      ],
    });
    doc.save('pedidos.pdf');
  };

  function openModalRegister() {
    dispatch(ActionsUsers.openModalCreateUser());
  }
  function handleCloseModal() {
    dispatch(ActionsUsers.closeModalCreateUser());
  }

  return (
    <ContainerTotal>
      <Header />
      {loading ? <LinearProgress /> : null}
      <Container>
        <div>
          <HeaderTotal text="Inicio / Usuários" />
          <HeaderTable>
            <ContainerButtonTotal>
              <ContainerButton>
                <ReactToExcel
                  table="table-users"
                  filename="Usuários"
                  sheet="sheet 1"
                  buttonText="EXCEL"
                />
              </ContainerButton>
              <ContainerButton>
                <button type="button" onClick={() => geraPdf()}>
                  PDF
                </button>
              </ContainerButton>
              <ContainerButton>
                <button
                  type="button"
                  onClick={() => {
                    openModalRegister();
                  }}
                >
                  NOVO
                </button>
              </ContainerButton>
            </ContainerButtonTotal>
            <InputWithButton
              labelInput="Pesquise pelo usuário"
              valueInput={inputUser}
              functionOnChange={text => setInputUser(text.target.value)}
              placeholderInput="Usuário"
              searchForSomething={() => {
                searchUser();
              }}
              removeFilter={() => {
                removeFilter();
              }}
            />
          </HeaderTable>
          <GridUsers
            id="table-users"
            users={users}
            moreDetailsUser={element => {
              moreDetailsUser(element);
            }}
          />
        </div>
        <Footer
          pageInitial={page}
          pageLast={lastPage}
          totalItens={total}
          functionInitialPage={() => {
            initialPage();
          }}
          functionBackPage={() => {
            backPageOrder();
          }}
          functionHandlePage={() => {
            paginateOrders();
          }}
          functionLastPage={() => {
            lastPageOrder();
          }}
        />
      </Container>
      <>
        <ModalUsers
          id="usuarios"
          modalVisible={modalUser}
          handleClose={() => {
            handleClose();
          }}
          data={user}
          tables={tables}
        />
        <RegisterUser
          modalVisible={modalCreate}
          handleClose={() => {
            handleCloseModal();
          }}
          data={user}
          tables={tables}
        />
      </>
    </ContainerTotal>
  );
}
export default memo(Users);
