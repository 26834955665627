import produce from 'immer';

const INITIAL_STATE = {
  duplicates: [],
  priceTotal: null,
  total: '0',
  page: 1,
  lastPage: 0,
  print: null,
};

export default function login(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@duplicate/REQUEST_DUPLICATES_SUCESS':
      return produce(state, draft => {
        draft.duplicates = action.payload.data;
      });
    case '@duplicate/SAVE_PRICE_TOTAL_DUPLICATE':
      return produce(state, draft => {
        draft.priceTotal = action.payload.priceTotal;
      });
    case '@duplicate/SAVE_PAGE':
      return produce(state, draft => {
        draft.total = action.payload.total;
        draft.page = action.payload.page;
        draft.lastPage = action.payload.lastPage;
      });
    case '@duplicate/REQUEST_DUPLICATE_PRINT_SUCESS':
      return produce(state, draft => {
        draft.print = action.payload.data;
      });
    case '@duplicate/CLEAN_APLICATION_DUPLICATES':
      return produce(state, draft => {
        draft.duplicates = [];
        draft.page = 1;
        draft.lastPage = 0;
        draft.total = '0';
        draft.priceTotal = null;
      });
    default:
      return state;
  }
}
