import produce from 'immer';

const INITIAL_STATE = {
  users: [],
  page: 1,
  lastPage: 0,
  total: '0',
  modalUser: false,
  modalCreate: false,
  tables: [],
};

export default function users(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@users/REQUEST_USERS_SUCESS':
      return produce(state, draft => {
        draft.users = action.payload.data;
      });
    case '@users/SAVE_PAGES':
      return produce(state, draft => {
        draft.page = action.payload.page;
        draft.lastPage = action.payload.lastPage;
        draft.total = action.payload.total;
      });
    case '@users/OPEN_MODAL_USER':
      return produce(state, draft => {
        draft.modalUser = true;
      });
    case '@users/CLOSE_MODAL_USER':
      return produce(state, draft => {
        draft.modalUser = false;
      });
    case '@users/OPEN_MODAL_CREATE_USER':
      return produce(state, draft => {
        draft.modalCreate = true;
      });
    case '@users/CLOSE_MODAL_CREATE_USER':
      return produce(state, draft => {
        draft.modalCreate = false;
      });
    case '@users/REQUEST_TABLE_PRICE_SUCESS':
      return produce(state, draft => {
        draft.tables = action.payload.data;
      });
    case '@users/CLEAN_APLICATION_USERS':
      return produce(state, draft => {
        draft.users = [];
        draft.page = 1;
        draft.lastPage = 0;
        draft.total = '0';
        draft.modalUser = false;
        draft.modalCreate = false;
        draft.tables = [];
      });
    default:
      return state;
  }
}
