import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../components/Header';
import HeaderTotal from '../../components/HeaderGrid';
// import Footer from '../../components/Footer';
import LinearProgress from '../../components/Loading';
import { ContainerTotal } from '../../components/Container';
import {
  Container,
  ContainerDiv,
  InputEdit,
  Options,
  ParamsTable,
  Selects,
} from './styles';

import * as ActionsParams from '../../store/modules/params/actions';

function Params() {
  const { params, carries } = useSelector(state => state.params);
  const { loading } = useSelector(state => state.common);

  const dispatch = useDispatch();
  const [inputEditOne, setInputEditOne] = useState(null);
  const [inputEditTwo, setInputEditTwo] = useState(null);
  // function paginateOrders() {
  //   if (lastPage > page) {
  //     const newPage = page + 1;
  //     dispatch(ActionsParams.requestParams(newPage));
  //   } else {
  //     dispatch(ActionsParams.requestParams(page));
  //   }
  // }
  // function initialPage() {
  //   dispatch(ActionsParams.requestParams(1));
  // }
  // function lastPageOrder() {
  //   dispatch(ActionsParams.requestParams(lastPage));
  // }
  // function backPageOrder() {
  //   if (lastPage >= page) {
  //     if (page >= 2) {
  //       const newPage = page - 1;
  //       dispatch(ActionsParams.requestParams(newPage));
  //     }
  //   } else {
  //     dispatch(ActionsParams.requestParams(page));
  //   }
  // }
  useEffect(() => {
    dispatch(ActionsParams.requestParams(1));
    dispatch(ActionsParams.requestCarrier());
  }, [dispatch]);
  const [idParam, setIdParam] = useState();
  const [edit, setEdit] = useState(true);
  function enableEdit(element) {
    setEdit(!edit);
    setIdParam(element.id);
    setInputEditOne(element.dias_cliente_inativo);
    setInputEditTwo(element.dias_data_faturamento);
  }
  function updateParams() {
    dispatch(
      ActionsParams.requestUpdateParams(
        idParam,
        inputEditOne,
        inputEditTwo,
        '2'
      )
    );
    setEdit(!edit);
  }

  return (
    <ContainerTotal>
      <Header />
      {loading ? <LinearProgress /> : null}
      <Container>
        <ContainerDiv>
          <HeaderTotal text="Inicio / Parâmetros" />
          <ParamsTable id="table-to-xls">
            <thead>
              <tr>
                <th>Dias de cliente inativo</th>
                <th>Dias de faturamento</th>
                <th>Transportadora padrão</th>
                <th>Opções</th>
              </tr>
            </thead>
            {edit ? (
              params.map(element => (
                <tr>
                  <td>
                    <strong>{element.dias_cliente_inativo}</strong>
                  </td>
                  <td>
                    <strong>{element.dias_data_faturamento}</strong>
                  </td>
                  <td>
                    <strong>{element.trasportadora_padrao}</strong>
                  </td>
                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        enableEdit(element);
                      }}
                    >
                      <strong>EDITAR</strong>
                    </button>
                  </div>
                </tr>
              ))
            ) : (
              <tr>
                <td>
                  <InputEdit
                    placeholder="Dias de cliente inativo"
                    value={inputEditOne}
                    onChange={text => setInputEditOne(text.target.value)}
                  />
                </td>
                <td>
                  <InputEdit
                    placeholder="Dias de faturamento"
                    value={inputEditTwo}
                    onChange={text => setInputEditTwo(text.target.value)}
                  />
                </td>
                <td>
                  <Selects name="Transportadora" form={carries.id}>
                    {carries.map(carrie => (
                      <Options value={carries.id}>{carrie.nome_razao}</Options>
                    ))}
                  </Selects>
                </td>
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      updateParams();
                    }}
                  >
                    <strong>ATUALIZAR</strong>
                  </button>
                </div>
              </tr>
            )}
            <tbody />
          </ParamsTable>
        </ContainerDiv>
      </Container>
    </ContainerTotal>
  );
}
export default memo(Params);
