import React from 'react';
import {
  // MdInsertDriveFile,
  MdChevronLeft,
  MdChevronRight,
  MdFirstPage,
  MdLastPage,
} from 'react-icons/md';
import PropTypes from 'prop-types';
import {
  Container,
  Button,
  ContainerButton,
  ContainerText,
  Text,
} from './styles';

export default function Footer({
  pageInitial,
  pageLast,
  totalItens,
  functionInitialPage,
  functionBackPage,
  functionHandlePage,
  functionLastPage,
}) {
  return (
    <Container>
      <ContainerButton>
        <Button
          onClick={() => {
            functionInitialPage();
          }}
        >
          <MdFirstPage />
        </Button>
        <Button
          onClick={() => {
            functionBackPage();
          }}
        >
          <MdChevronLeft />
        </Button>
        <Button
          onClick={() => {
            functionHandlePage();
          }}
        >
          <MdChevronRight />
        </Button>
        <Button
          onClick={() => {
            functionLastPage();
          }}
        >
          <MdLastPage />
        </Button>
      </ContainerButton>
      <ContainerText>
        <Text>
          {pageInitial} - {pageLast} de {totalItens} itens
        </Text>
      </ContainerText>
    </Container>
  );
}
Footer.propTypes = {
  pageInitial: PropTypes.number,
  pageLast: PropTypes.number,
  totalItens: PropTypes.string,
  functionInitialPage: PropTypes.func,
  functionBackPage: PropTypes.func,
  functionHandlePage: PropTypes.func,
  functionLastPage: PropTypes.func,
};
Footer.defaultProps = {
  pageInitial: 0,
  pageLast: 0,
  totalItens: '0',
  functionInitialPage: () => {},
  functionBackPage: () => {},
  functionHandlePage: () => {},
  functionLastPage: () => {},
};
