export function requestLogin(username, password) {
  return {
    type: '@login/REQUEST_LOGIN',
    payload: { username, password },
  };
}
export function requestLoginExist() {
  return {
    type: '@login/REQUEST_LOGIN_EXIST',
  };
}
export function requestUsername() {
  return {
    type: '@login/REQUEST_USERNAME',
  };
}
export function requestUser(data) {
  return {
    type: '@login/REQUEST_USER',
    payload: { data },
  };
}
