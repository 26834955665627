import styled from 'styled-components';
import { darken } from 'polished';
import { MdEdit, MdDelete } from 'react-icons/md';
import { colors } from '../../styles';

export const Container = styled.div``;

export const ContainerInfo = styled.form`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 10px;
`;
export const ContainerText = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 10px;
`;
export const HeaderModal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  padding-top: 30px;
  flex-direction: row;
  height: 48px;
  border-radius: 8px;
  div {
    strong {
      font-size: 24px;
    }
  }
  button {
    border: 0px;
    background: none;
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
`;
export const ContainerOne = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ContainerBody = styled.div`
  width: 300px;
  padding: 10px;
`;

export const ContainerTwo = styled.table`
  padding: 15px;
  width: 300px;

  thead th {
    color: #999;
    text-align: left;
    /* padding-bottom: 30px; */

    padding-bottom: 10px;
  }
  thead td {
    text-align: left;
  }
  tbody tr td {
    width: 300px;
    height: 35px;
    text-align: left;
    padding-left: 1px;
  }
  /* tbody tr {
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  } */
  tbody tr div {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      background: none;
      border: 0px;
      padding: 6px;
      transition: background 0.2s;
      &:hover {
        background: ${darken(0.03, '#777')};
      }
    }
  }
`;

export const Input = styled.input`
  border: 0.4px solid #707070;
  height: 36px;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  :focus {
    border-color: ${colors.secundary};
    border: 0.8px solid ${colors.secundary};
  }
`;

export const Tables = styled.div`
  border: 1px solid #707070;
  border-radius: 8px;
  height: 400px;
  overflow: scroll;
`;

export const Text = styled.strong.attrs({
  numberOfLines: 1,
})`
  font-size: 16px;
  font-weight: 200;
  margin-top: 10px;
`;
export const TextBold = styled.strong.attrs({
  numberOfLines: 1,
})`
  font-size: 16px;
  font-weight: bold;
`;

export const TextNormal = styled.span`
  font-size: 16px;
  font-weight: 200;
`;
export const DivListTables = styled.div`
  border: 0.2px solid #888;
  width: 300px;
  height: 50px;

  strong {
  }
`;
export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 60px;

  padding: 10px;
`;

export const ContainerButtonTotal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ContainerButton = styled.div`
  padding: 2px;
`;

export const ButtonEdit = styled.button`
  height: 40px;
  width: 40px;
  border: 0;
  background: ${colors.primary};
  border-radius: 8px;
  transition: background 0.2s;
  &:hover {
    background: ${darken(0.07, colors.primary)};
  }
`;

export const ButtonDelete = styled.button`
  height: 40px;
  width: 40px;
  border-radius: 8px;
  border: 0;
  background: ${colors.error};
  transition: background 0.2s;
  &:hover {
    background: ${darken(0.07, colors.error)};
  }
`;

export const IconEdit = styled(MdEdit).attrs({
  size: 22,
  color: '#fff',
})``;

export const IconDelete = styled(MdDelete).attrs({
  size: 22,
  color: '#fff',
})``;

export const Button = styled.button`
  height: 40px;
  width: 100px;
  border: 0;
  background: ${colors.secundary};
  border-radius: 8px;
  transition: background 0.2s;
  &:hover {
    background: ${darken(0.07, colors.secundary)};
  }
`;
export const ContainerSelect = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  button {
    height: 40px;
    width: 40px;
    border-radius: 8px;
    border: 0;
    background: ${colors.primary};
    /* margin-top: 10px; */
    margin-left: 10px;
    transition: background 0.2s;
    &:hover {
      background: ${darken(0.07, colors.primary)};
    }
  }
`;
export const Select = styled.select`
  padding: 8px;
  height: 40px;
  width: 230px;
  border-radius: 8px;
`;

export const SelectPermission = styled.select`
  padding: 8px;
  height: 40px;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 10px;
  margin-top: 10px;
`;
