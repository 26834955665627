import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';

import history from '../../../services/history';
import { failureAction, loading, successAction } from '../common/actions';
import {
  requestCarrierSucess,
  requestParamsSucess,
  savePageParams,
} from './actions';
// import { UserException, errorVerify } from '../../../config/Exceptions';

let token = localStorage.getItem('NovaDublagem@Web_Token');
token = JSON.parse(token);

function* handleParams() {
  yield put(loading());
  history.push('/sistema/home/Params');
  yield put(successAction());
}
function* backHome() {
  yield put(loading());
  history.push('/sistema/home');
  yield put(successAction());
}
function* requestParams(action) {
  yield put(loading());
  // const { page } = action.payload;

  try {
    const { data } = yield call(api.get, `/parametro`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    yield put(requestParamsSucess(data.data));
    yield put(savePageParams(data.page, data.lastPage, data.total));
    yield put(successAction());
  } catch (err) {
    const menssage = 'Erro ao buscar os parâmetros';
    toast.error(menssage);
    yield put(failureAction(menssage));
  }
}

function* requestCarrier() {
  yield put(loading());
  try {
    const { data } = yield call(api.get, `/cliente?transportadora=`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    yield put(requestCarrierSucess(data));
  } catch (err) {
    const menssage = 'Erro ao buscar as transportadora';
    toast.error(menssage);
    yield put(failureAction(menssage));
  }
}
function* requestUpdateParams(action) {
  yield put(loading());

  const {
    id,
    dias_cliente_inativo,
    dias_data_faturamento,
    trasportadora_padrao,
  } = action.payload;

  const newData = {
    id,
    dias_cliente_inativo,
    dias_data_faturamento,
    trasportadora_padrao,
  };

  try {
    const response = yield call(api.put, `/parametro/${id}`, newData, { // eslint-disable-line
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // yield put(requestCarrierSucess(data));
    const { data } = yield call(api.get, `/parametro`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put(requestParamsSucess(data.data));
  } catch (err) {
    const menssage = 'Erro ao atualizar os parâmetros';
    toast.error(menssage);
    yield put(failureAction(menssage));
  }
}
export default all([
  takeLatest('@params/HANDLE_PARAMS', handleParams),
  takeLatest('@params/BACK_HOME', backHome),
  takeLatest('@params/REQUEST_PARAMS', requestParams),
  takeLatest('@params/REQUEST_CARRIER', requestCarrier),
  takeLatest('@params/REQUEST_UPDATE_PARAMS', requestUpdateParams),
]);
