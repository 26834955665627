import styled from 'styled-components';
import { colors } from '../../styles';

export const Container = styled.div`
  display: flex;
  align-items: center;
  button {
    border: 0;
    background: ${colors.secundary};
    margin-right: 2px;
    padding: 10px;
    border-radius: 8px;
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
`;

export const InputText = styled.div``;
