import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import PropTypes from 'prop-types';
import { MdClose } from 'react-icons/md';
import Image from '../../assets/images/image.jpg';

import {
  Container,
  ContainerInfo,
  TextBold,
  TextNormal,
  ContainerText,
  Item,
  Text,
  HeaderModal,
} from './styles';

export default function ModalInfo({ modalVisible, handleClose, data }) {
  // const [doubleGrid, setDoubleGrid] = useState(true);

  const useStyles = makeStyles(theme => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '0',
      borderRadius: '8px',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      height: `400px`,
      overflow: 'scroll',
    },
  }));
  const classes = useStyles();
  return (
    <Container>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modalVisible}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalVisible}>
          <div className={classes.paper}>
            {data.length === 0 ? null : (
              <>
                <HeaderModal>
                  <div>
                    {' '}
                    <strong>Detalhes do pedido</strong>
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    <MdClose size={25} />
                  </button>
                </HeaderModal>
                <ContainerInfo>
                  <ContainerText>
                    <TextBold>
                      Cliente:{' '}
                      <TextNormal>{data.cliente.nome_razao}</TextNormal>
                    </TextBold>
                    <TextBold>
                      Embalagem:{' '}
                      <TextNormal>{data.embalagem.descricao}</TextNormal>
                    </TextBold>
                    <TextBold>
                      Tipo:{' '}
                      <TextNormal>{data.tipoCobranca.descricao}</TextNormal>
                    </TextBold>
                  </ContainerText>
                  <ContainerText>
                    <TextBold>
                      Representante:{' '}
                      <TextNormal>{data.representante.nome_razao}</TextNormal>
                    </TextBold>
                    <TextBold>
                      Condição de pagamento:{' '}
                      <TextNormal>
                        {data.condicaoPagamento.descricao}
                      </TextNormal>
                    </TextBold>
                    <TextBold>
                      Código do pedido:{' '}
                      <TextNormal>{data.pedido_cod}</TextNormal>
                    </TextBold>
                  </ContainerText>
                  <ContainerText>
                    <TextBold>Itens do pedido:</TextBold>
                  </ContainerText>
                  <Item>
                    <thead>
                      <tr>
                        <th>Imagem</th>
                        <th>Descrição</th>
                        <th>Modelo</th>
                        <th>Caracteristicas</th>
                        <th>Valor unitário</th>
                        <th>Quantidade</th>
                        <th>Valor total</th>
                      </tr>
                    </thead>
                    {data.pedidoItens.map(element => (
                      <tbody>
                        <tr>
                          <div>
                            <img
                              src={
                                element.linhamatriz.imageUrl ===
                                'Não encontrado'
                                  ? Image
                                  : element.linhamatriz.imageUrl
                              }
                              alt=""
                            />
                          </div>

                          <td>
                            <strong>
                              {element.linhamatriz.linha.descricao}
                            </strong>
                          </td>
                          <td>
                            <strong>{element.linhamatriz.matriz}</strong>
                          </td>
                          <td>
                            <Text>{element.linhamatriz.caracteristicas}</Text>
                          </td>
                          <td>
                            <strong>{element.valor_real}</strong>
                          </td>
                          <td>
                            <strong>{element.quantidade_item_total}</strong>
                          </td>
                          <td>
                            <strong>
                              R$ {Number(element.preco_item_total).toFixed(2)}
                            </strong>
                          </td>
                        </tr>
                        {/* {doubleGrid ? (
                          <tr>
                            <div />
                            <strong>
                              R$ {Number(element.preco_item_total).toFixed(2)}
                            </strong>
                          </tr>
                        ) : null} */}
                      </tbody>
                    ))}
                  </Item>
                </ContainerInfo>
              </>
            )}
          </div>
        </Fade>
      </Modal>
    </Container>
  );
}
ModalInfo.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  modalVisible: PropTypes.bool,
  handleClose: PropTypes.func,
};
ModalInfo.defaultProps = {
  data: [{}],
  modalVisible: false,
  handleClose: () => {},
};
