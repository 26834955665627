import styled from 'styled-components';
import { darken } from 'polished';
import { colors } from '../../styles';

export const Container = styled.div`
  width: 100%;
  height: 48px;
  border-radius: 4px;
`;
export const Submit = styled.button`
  width: 100%;
  height: 100%;
  background: ${colors.secundary};
  border-width: 0;
  border-radius: 4px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  transition: background 0.2s;
  &:hover {
    background: ${darken(0.04, `${colors.secundary}`)};
  }
`;
export const TextButton = styled.text`
  font-weight: bold;
`;
