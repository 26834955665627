import React, { useEffect, useState } from 'react';
import ReactToExcel from 'react-html-table-to-excel';
import { useDispatch, useSelector } from 'react-redux';
import * as jsPDF from 'jspdf';
import Header from '../../components/Header';
import HeaderTotal from '../../components/HeaderGrid';
import InputWithButton from '../../components/InputWithButton';
import GridBillings from '../../components/GridBillings';
import SearchByDate from '../../components/SearchByDate';
import LinearProgress from '../../components/Loading';
import ModalDetails from '../../components/ModalBilling';
import Footer from '../../components/Footer';
import {
  ContainerTotal,
  ContainerButtonTotal,
  ContainerButton,
  HeaderTable,
} from '../../components/Container';
import { Container } from './styles';
import * as NoteActions from '../../store/modules/note/actions';
import 'jspdf-autotable';

export default function Billing() {
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.common);
  const {
    billings,
    total,
    page,
    lastPage,
    modalDetails,
    detailsNote,
  } = useSelector(state => state.note);
  const [inputClient, setInputClient] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDate2, setSelectedDate2] = useState(new Date());

  useEffect(() => {
    dispatch(NoteActions.requestBillings(1, ''));
  }, [dispatch]);
  function initialPage() {
    dispatch(NoteActions.requestBillings(1, ''));
  }
  function lastPageOrder() {
    dispatch(NoteActions.requestBillings(lastPage, ''));
  }
  function backPageOrder() {
    if (lastPage >= page) {
      if (page >= 2) {
        const newPage = page - 1;
        dispatch(NoteActions.requestBillings(newPage, ''));
      }
    } else {
      dispatch(NoteActions.requestBillings(page, ''));
    }
  }
  function paginateOrders() {
    if (lastPage > page) {
      const newPage = page + 1;
      dispatch(NoteActions.requestBillings(newPage, ''));
    } else {
      dispatch(NoteActions.requestBillings(page, ''));
    }
  }
  function searchBillingId() {
    dispatch(NoteActions.requestBillings(1, inputClient));
  }
  function removeFilter() {
    dispatch(NoteActions.requestBillings(1));
    setInputClient('');
  }
  const geraPdf = () => {
    const doc = new jsPDF('p', 'pt');// eslint-disable-line
    doc.autoTable({
      body: billings,
      columns: [
        { header: 'Nº nota', dataKey: 'notatransformad' },
        { header: 'Cliente', dataKey: 'nome_razao' },
        { header: 'Emissão', dataKey: 'emissao' },
        { header: 'Condição pagamento', dataKey: 'descricao' },
      ],
    });
    doc.save('faturamento.pdf');
  };
  const handleDateChange = date => {
    setSelectedDate(date);
  };

  const handleDateChange2 = date => {
    setSelectedDate2(date);
  };
  function removeFilterEmission() {
    dispatch(NoteActions.requestBillings(1, ''));
    setSelectedDate(new Date());
    setSelectedDate2(new Date());
  }
  function searchBilllingsDate() {
    dispatch(
      NoteActions.requestBillingsForDate(1, 8, selectedDate, selectedDate2)
    );
  }
  function moreDetailsBilling(note) {
    dispatch(NoteActions.requestDetailsBilling(note.id));
  }
  function closeModalDetails() {
    dispatch(NoteActions.openModalDetailsBilling(false));
  }
  return (
    <ContainerTotal>
      <Header />
      {loading ? <LinearProgress /> : null}
      <Container>
        <div>
          <HeaderTotal text="Inicio / Faturamento" />
          <HeaderTable>
            <ContainerButtonTotal>
              <ContainerButton>
                <ReactToExcel
                  table="table-billings"
                  filename="Relatório"
                  sheet="sheet 1"
                  buttonText="EXCEL"
                />
              </ContainerButton>
              <ContainerButton>
                <button
                  type="button"
                  onClick={() => {
                    geraPdf();
                  }}
                >
                  PDF
                </button>
              </ContainerButton>
            </ContainerButtonTotal>
            <InputWithButton
              labelInput="Pesquise pelo cliente"
              placeholderInput="Cliente"
              valueInput={inputClient}
              functionOnChange={text => setInputClient(text.target.value)}
              removeFilter={() => {
                removeFilter();
              }}
              searchForSomething={() => {
                searchBillingId();
              }}
            />
            <SearchByDate
              handleDateChange={date => {
                handleDateChange(date);
              }}
              handleDateChange2={date => {
                handleDateChange2(date);
              }}
              valueInitial={selectedDate}
              valueEnd={selectedDate2}
              searchByDate={() => {
                searchBilllingsDate();
              }}
              removeFilterDate={() => {
                removeFilterEmission();
              }}
            />
          </HeaderTable>
          <GridBillings
            data={billings}
            id="table-billings"
            moreDetails={note => {
              moreDetailsBilling(note);
            }}
          />
        </div>
        <Footer
          pageInitial={page}
          pageLast={lastPage}
          totalItens={total}
          functionInitialPage={() => {
            initialPage();
          }}
          functionBackPage={() => {
            backPageOrder();
          }}
          functionHandlePage={() => {
            paginateOrders();
          }}
          functionLastPage={() => {
            lastPageOrder();
          }}
        />
      </Container>
      <>
        <ModalDetails
          modalVisible={modalDetails}
          data={detailsNote}
          handleClose={() => {
            closeModalDetails();
          }}
        />
      </>
    </ContainerTotal>
  );
}
