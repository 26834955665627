import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import AccountCircle from '@material-ui/icons/AccountCircle';
// import VpnKey from '@material-ui/icons/VpnKey';
import * as LoginActions from '../../store/modules/login/actions';
import Loading from '../../components/Loading';

import Input from '../../components/Input';

import {
  AreaImage,
  Container,
  ContainerInput,
  Form,
  Image,
  TextButton,
} from './styles';
import Logo from '../../assets/images/logoWeb.png';
import Button from '../../components/Button';

export default function Login() {
  const { loading } = useSelector(state => state.common);
  // const { users } = useSelector(state => state.login);
  const dispatch = useDispatch();
  const [inputUsername, setInputUsername] = useState(null);
  const [inputPassword, setInputPassword] = useState(null);
  useEffect(() => {
    dispatch(LoginActions.requestLoginExist());
  }, [dispatch]); //
  function requestLogin(e) {
    e.preventDefault();
    dispatch(LoginActions.requestLogin(inputUsername, inputPassword));
    setInputPassword(null);
    setInputUsername(null);
  }
  return (
    <>
      {loading ? <Loading /> : null}
      <Container>
        <AreaImage>
          <Image src={Logo} alt="novaDublagem" />
        </AreaImage>
        <Form onSubmit={e => requestLogin(e)}>
          <ContainerInput>
            <Input
              placeholder="E-mail"
              typeIcon="User"
              valueInput={inputUsername}
              functionOnChange={text => setInputUsername(text)}
            />
          </ContainerInput>
          <ContainerInput>
            <Input
              placeholder="Senha"
              type="password"
              typeIcon="Password"
              valueInput={inputPassword}
              functionOnChange={text => setInputPassword(text)}
            />
          </ContainerInput>
          <Button titleButton="ENTRAR" />
          <TextButton href="/sistema/registerclient">
            Não possui cadastro? Clique aqui
          </TextButton>
        </Form>
      </Container>
    </>
  );
}
