import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ReactToExcel from 'react-html-table-to-excel';
import * as jsPDF from 'jspdf';
import Header from '../../components/Header';
import InputWithButton from '../../components/InputWithButton';
import { ContainerTotal } from '../../components/Container';
import Footer from '../../components/Footer';
import GridOrders from '../../components/GridOrders';
import HeaderTotal from '../../components/HeaderGrid';
import ModalInfo from '../../components/Modal';
import SearchByDate from '../../components/SearchByDate';
import LinearProgress from '../../components/Loading';
import 'jspdf-autotable';
import {
  Container,
  HeaderTable,
  ContainerButton,
  ContainerButtonTotal,
  // ButtonExcel,
} from './styles';

import * as ActionsOrder from '../../store/modules/orders/actions';

export default function Home() {
  const dispatch = useDispatch();
  const { orders, total, page, lastPage } = useSelector(state => state.orders);
  const { loading } = useSelector(state => state.common);
  const [modal, setModal] = useState(false);
  const [orderInfo, setOrderInfo] = useState([]);
  const [inputCod, setInputCod] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDate2, setSelectedDate2] = useState(new Date());
  useEffect(() => {
    dispatch(ActionsOrder.requestOrders(1));
  }, []); // eslint-disable-line
  function moreDetailsOrder(element) {
    setOrderInfo(element);
    setModal(!modal);
  }

  function searchOrderId() {
    if (inputCod !== '') {
      dispatch(ActionsOrder.requestOrdersForId(String(inputCod)));
    } else {
      dispatch(ActionsOrder.requestOrders());
    }
  }

  function searchOrderDate() {
    dispatch(
      ActionsOrder.requestOrdersForEmission(selectedDate, selectedDate2)
    );
  }
  function removeFilter() {
    dispatch(ActionsOrder.requestOrders(1));
    setInputCod('');
  }
  function removeFilterEmission() {
    dispatch(ActionsOrder.requestOrders(1));
    setSelectedDate(new Date());
    setSelectedDate2(new Date());
  }
  function paginateOrders() {
    if (lastPage > page) {
      const newPage = page + 1;
      dispatch(ActionsOrder.requestOrders(newPage));
    } else {
      dispatch(ActionsOrder.requestOrders(page));
    }
  }
  function initialPage() {
    dispatch(ActionsOrder.requestOrders(1));
  }
  function lastPageOrder() {
    dispatch(ActionsOrder.requestOrders(lastPage));
  }
  function backPageOrder() {
    if (lastPage >= page) {
      if (page >= 2) {
        const newPage = page - 1;
        dispatch(ActionsOrder.requestOrders(newPage));
      }
    } else {
      dispatch(ActionsOrder.requestOrders(page));
    }
  }

  const geraPdf = () => {
    const doc = new jsPDF('p', 'pt');// eslint-disable-line
    doc.autoTable({
      body: orders,
      columns: [
        { header: 'ID do pedido', dataKey: 'id' },
        { header: 'cod industria', dataKey: 'pedido_industria' },
        { header: 'Situação', dataKey: 'situacao' },
        {
          header: 'Quantidade total',
          dataKey: 'quantidade_pares_total',
        },
        { header: 'Valor total', dataKey: 'valor_total_pedido' },
      ],
    });
    doc.save('pedidos.pdf');
  };
  const handleDateChange = date => {
    setSelectedDate(date);
  };

  const handleDateChange2 = date => {
    setSelectedDate2(date);
  };

  return (
    <ContainerTotal>
      <Header />
      {loading ? <LinearProgress /> : null}
      <Container>
        <div>
          <HeaderTotal text="Inicio / Pedidos" />
          <HeaderTable>
            <ContainerButtonTotal>
              <ContainerButton>
                <ReactToExcel
                  table="table-to-xls"
                  filename="Relatório"
                  sheet="sheet 1"
                  buttonText="EXCEL"
                />
              </ContainerButton>
              <ContainerButton>
                <button type="button" onClick={() => geraPdf()}>
                  PDF
                </button>
              </ContainerButton>
            </ContainerButtonTotal>
            <InputWithButton
              labelInput="Pesquise o id do pedido"
              placeholderInput="id"
              valueInput={inputCod}
              functionOnChange={text => setInputCod(text.target.value)}
              removeFilter={() => {
                removeFilter();
              }}
              searchForSomething={() => {
                searchOrderId();
              }}
            />
            <SearchByDate
              handleDateChange={date => {
                handleDateChange(date);
              }}
              handleDateChange2={date => {
                handleDateChange2(date);
              }}
              valueInitial={selectedDate}
              valueEnd={selectedDate2}
              searchByDate={() => {
                searchOrderDate();
              }}
              removeFilterDate={() => {
                removeFilterEmission();
              }}
            />
          </HeaderTable>
          <GridOrders
            moreDetailsOrder={element => {
              moreDetailsOrder(element);
            }}
            orders={orders}
            id="table-to-xls"
          />
        </div>
        <Footer
          pageInitial={page}
          pageLast={lastPage}
          totalItens={total}
          functionInitialPage={() => {
            initialPage();
          }}
          functionBackPage={() => {
            backPageOrder();
          }}
          functionHandlePage={() => {
            paginateOrders();
          }}
          functionLastPage={() => {
            lastPageOrder();
          }}
        />
      </Container>
      <>
        <ModalInfo
          modalVisible={modal}
          handleClose={() => setModal(!modal)}
          data={orderInfo}
        />
      </>
    </ContainerTotal>
  );
}
Home.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      index: PropTypes.string,
    }),
  }),
};
Home.defaultProps = {
  match: {
    params: {
      index: '0',
    },
  },
};
