import axios from 'axios';

const api = axios.create({
  baseURL: 'https://novadublagemweb.com.br',
  // baseURL: 'https://novadublagem.herokuapp.com',
  // baseURL: 'https://backnovadublagem.herokuapp.com',
  // baseURL: 'https://apinovadub.herokuapp.com',
});

export default api;
