import React from 'react';
import PropTypes from 'prop-types';
import { Container, Input } from './styles';

export default function InputText({
  label,
  placeholder,
  value,
  functionOnChange,
}) {
  return (
    <Container>
      <Input
        label={label}
        placeholder={placeholder}
        onChange={functionOnChange}
        value={value}
      />
    </Container>
  );
}
InputText.propTypes = {
  // modalVisible: PropTypes.bool,
  functionOnChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};
InputText.defaultProps = {
  // modalVisible: false,
  functionOnChange: () => {},
  label: 'text',
  placeholder: 'text',
  value: '',
};
