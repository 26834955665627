import { all, call, cancel, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';

import { requestUser } from './actions';
import history from '../../../services/history';
import { failureAction, loading, successAction } from '../common/actions';

// import { UserException, errorVerify } from '../../../config/Exceptions';

function* requestLogin(action) {
  yield put(loading());
  try {
    const { username, password } = action.payload;

    const { data } = yield call(api.post, '/login', {
      username,
      password,
    });
    const { username: user, permission } = data;
    const newData = {
      user,
      permission,
    };

    localStorage.setItem('NovaDublagem@Web', JSON.stringify(newData));
    localStorage.setItem('NovaDublagem@Web_Token', JSON.stringify(data.token));
    yield put(requestUser(data));
    if (data.permission === 'Administrador') {
      history.push('/sistema/home');
      yield put(successAction());
    } else if (data.permission === 'Cliente') {
      history.push('/sistema/home');
      yield put(successAction());
    } else {
      localStorage.removeItem('NovaDublagem@Web');
      history.push('/');
      const menssage = 'Erro ao logar';

      toast.error(menssage);
      yield put(successAction());
    }

    yield put(successAction());
  } catch (error) {
    const menssage = 'Erro ao logar';

    toast.error(menssage);
    history.push('/');
    yield put(failureAction(menssage));
  }
}
function* requestLoginExistSaga() {
  let data = localStorage.getItem('NovaDublagem@Web');
  data = JSON.parse(data);
  yield put(requestUser(data));
  try {
    const { token, permission } = data;

    if (token === null) {
      yield put(failureAction(''));

      yield cancel;
    } else {
      yield call(api.get, '/users', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      yield put(successAction());
    }
    if (permission === 'Administrador') {
      history.push('/sistema/home');
      yield put(successAction());
    } else if (permission === 'Cliente') {
      history.push('/sistema/home');
      yield put(successAction());
    } else {
      localStorage.removeItem('NovaDublagem@Web');
      history.push('/');
      yield put(successAction());
    }
    yield put(successAction());
  } catch (err) {
    yield put(failureAction(''));
  }
  yield put(successAction());
}

function* requestUsernameSaga() {
  let data = localStorage.getItem('NovaDublagem@Web');
  data = JSON.parse(data);
  yield put(requestUser(data));
}

export default all([
  takeLatest('@login/REQUEST_LOGIN', requestLogin),
  takeLatest('@login/REQUEST_LOGIN_EXIST', requestLoginExistSaga),
  takeLatest('@login/REQUEST_USERNAME', requestUsernameSaga),
]);
