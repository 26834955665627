import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import { requestValidationOfClientSucess } from './actions';
import { failureAction, loading, successAction } from '../common/actions';
import history from '../../../services/history';

// let token = localStorage.getItem('NovaDublagem@Web_RegisterClient');
// token = JSON.parse(token);

function* requestValidatorClient(action) {
  yield put(loading());

  try {
    const { data } = yield call(api.post, '/verificacnpj', action.payload);

    if (data.message === 'Cliente não encontrado') {
      toast.warn(
        'Cliente não encontrado. Solicitar revisão do cadastro. Contato: (16)3713-2100'
      );
    } else if (data.message === 'Cliente já cadastrado') {
      toast.warn('Cliente já cadastrado');
    } else if (
      'Cliente não encontrado. Solicite revisão do cadastro do cliente. Contato: (16) 3713-2100'
    ) {
      toast.warn(
        'Cliente não encontrado. Solicite revisão do cadastro do cliente. Contato: (16) 3713-2100'
      );
    } else {
      yield put(requestValidationOfClientSucess(data));
      localStorage.setItem(
        'NovaDublagem@Web_RegisterClient',
        JSON.stringify(data.token)
      );
      const { cnpj } = action.payload;
      localStorage.setItem('NovaDublagem@Web_Cnpj', JSON.stringify(cnpj));
    }
    yield put(successAction());
  } catch (err) {
    const message =
      'Cliente não encontrado. Solicitar revisão do cadastro. Contato: (16)3713-2100 ';
    toast.error(message);
    yield put(failureAction(message));
  }
}
function* requestCreateUser({ payload }) {
  yield put(loading());
  const { username, password } = payload;
  let cnpj = localStorage.getItem('NovaDublagem@Web_Cnpj');
  cnpj = JSON.parse(cnpj);
  const data2 = {
    username,
    password,
    email: username,
    permission: 'Cliente',
    status: false,
    cnpj,
  };
  try {
    const { data } = yield call(api.post, '/criaUsuarioCliente', data2);
    localStorage.setItem('NovaDublagem@Web', JSON.stringify(data));
    localStorage.setItem('NovaDublagem@Web_Token', JSON.stringify(data.token));
    history.push('/sistema/home');
    yield put(successAction());
  } catch (err) {
    const message = 'Erro ao criar o usuário';
    toast.warn(message);
    yield put(failureAction(message));
  }
}

export default all([
  takeLatest('@validator/REQUEST_VALIDATION_OF_CLIENT', requestValidatorClient),
  takeLatest('@validator/REQUEST_CREATE_USER_CLIENT', requestCreateUser),
]);
