import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as ValidatorActions from '../../store/modules/validatorclient/actions';

import Input from '../../components/Input';

import { cnpjMask } from '../../utils/format';

import {
  AreaImage,
  Container,
  ContainerInput,
  Image,
  Text,
  Title,
} from './styles';
import Button from '../../components/Button';
import Logo from '../../assets/images/logoWeb.png';

export default function ValidatorClient() {
  const dispatch = useDispatch();
  const { info } = useSelector(state => state.validatorclient);
  const [inputCnpj, setInputCnpj] = useState(null);
  function validatorCnpj() {
    if (inputCnpj !== null) {
      dispatch(ValidatorActions.requestValidationOfClient(inputCnpj));
      setInputCnpj(null);
    }
  }
  return (
    <Container>
      {info.message === 'Cliente não encontrado' ||
      info.length === 0 ||
      info.message === 'Cliente já cadastrado' ? (
        <>
          <AreaImage>
            <Image src={Logo} alt="novaDublagem" />
          </AreaImage>
          <Title>Registrador</Title>
          <Text>Digite o CNPJ para buscar o cadastro do cliente.</Text>
          <ContainerInput>
            <Input
              placeholder="CNPJ"
              valueInput={inputCnpj}
              functionOnChange={text => {
                setInputCnpj(cnpjMask(text));
              }}
              maxLength={18}
            />
          </ContainerInput>
          <Button
            titleButton="PESQUISAR"
            functionOnClick={() => {
              validatorCnpj();
            }}
          />
        </>
      ) : (
        <>
          <AreaImage>
            <Image src={Logo} alt="novaDublagem" />
          </AreaImage>
          <Title>Registrador</Title>
          <Text>
            Foi enviado um link para seu email ({info.email}) para que você
            termine seu cadastro.
          </Text>
        </>
      )}
    </Container>
  );
}
