import React from 'react';
import PropTypes from 'prop-types';
import { Container, Submit, TextButton } from './styles';

export default function Button({ titleButton, functionOnClick }) {
  return (
    <Container>
      <Submit onClick={() => functionOnClick()}>
        <TextButton>{titleButton}</TextButton>
      </Submit>
    </Container>
  );
}
Button.propTypes = {
  titleButton: PropTypes.string,
  functionOnClick: PropTypes.func,
};
Button.defaultProps = {
  titleButton: 'TITLE',
  functionOnClick: () => {},
};
