import styled from 'styled-components';
import { colors } from '../../styles';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  /* background: #555; */
  justify-content: center;
  align-items: center;
`;
export const ButtonEmission = styled.button`
  border: 0;
  background: ${colors.secundary};
  margin-left: 2px;
  padding: 10px;
  height: 40px;
  margin-top: 10px;
  border-radius: 8px;
  transition: opacity 0.2s;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;
