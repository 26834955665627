import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import VpnKey from '@material-ui/icons/VpnKey';
import { Container, TextInput, AreaInput, AreaIcon, Icon } from './styles';

export default function Input({
  valueInput,
  functionOnChange,
  placeholder,
  iconExist,
  typeOf,
  typeIcon,
  maxLength,
}) {
  function returnIcon() {
    if (typeIcon === 'User') {
      return <Icon />;
    }
    if (typeIcon === 'Password') {
      return <VpnKey />;
    }
    return <Icon />;
  }

  const [isFocused, setIsFocused] = useState(false);
  const handleOnFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  let valorInput;

  if (!valueInput) {
    valorInput = '';
  } else {
    valorInput = valueInput;
  }

  return (
    <Container isFocused={isFocused}>
      <AreaInput>
        <TextInput
          type={typeOf}
          placeholder={placeholder}
          value={valorInput}
          onChange={text => functionOnChange(text.target.value)}
          autoComplete="false"
          security="false"
          onFocus={handleOnFocus}
          onBlur={handleInputBlur}
          maxLength={maxLength}
        />
      </AreaInput>
      {iconExist ? <AreaIcon>{returnIcon(typeIcon)}</AreaIcon> : null}
    </Container>
  );
}
Input.propTypes = {
  placeholder: PropTypes.string,
  typeOf: PropTypes.string,
  valueInput: PropTypes.string,
  typeIcon: PropTypes.string,
  functionOnChange: PropTypes.func,
  iconExist: PropTypes.bool,
  maxLength: PropTypes.number,
};
Input.defaultProps = {
  placeholder: 'label',
  typeOf: 'text',
  valueInput: '',
  typeIcon: '',

  functionOnChange: () => {},
  iconExist: true,
  maxLength: 255,
};
