import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import jsPDF from 'jspdf';

// import { renderToString } from 'react-dom/server';

import Header from '../../components/Header';
import { ContainerTotal } from '../../components/Container';
import HeaderTotal from '../../components/HeaderGrid';
import Footer from '../../components/Footer';
import GridDulicate from '../../components/GridDuplicate';
import SearchByDate from '../../components/SearchByDate';
import InputWithButton from '../../components/InputWithButton';
import LinearProgress from '../../components/Loading';
import {
  Container,
  HeaderTable,
  ContainerValue,
  TextPrice,
  PriceTotal,
} from './styles';

import * as ActionsDuplicate from '../../store/modules/duplicate/actions';

export default function Duplicate() {
  const dispatch = useDispatch();
  const { duplicates, total, page, lastPage, priceTotal } = useSelector(
    state => state.duplicate
  );

  const { loading } = useSelector(state => state.common);
  const [inputNumberDuplicate, setInputNumberDuplicate] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDate2, setSelectedDate2] = useState(new Date());

  useEffect(() => {
    dispatch(ActionsDuplicate.requestDuplicates(1));
  }, [dispatch]);

  function searchDuplicateForNumber() {
    dispatch(
      ActionsDuplicate.requestDuplicatesForNumber(String(inputNumberDuplicate))
    );
  }

  function removeFilterForNumber() {
    dispatch(ActionsDuplicate.requestDuplicates(1));
    setInputNumberDuplicate('');
  }
  function paginateDulicate() {
    if (lastPage > page) {
      const newPage = page + 1;
      dispatch(ActionsDuplicate.requestDuplicates(newPage));
    } else {
      dispatch(ActionsDuplicate.requestDuplicates(page));
    }
  }
  function initialPage() {
    dispatch(ActionsDuplicate.requestDuplicates(1));
  }
  function lastPageOrder() {
    dispatch(ActionsDuplicate.requestDuplicates(lastPage));
  }
  function backPageOrder() {
    if (lastPage >= page) {
      if (page >= 2) {
        const newPage = page - 1;
        dispatch(ActionsDuplicate.requestDuplicates(newPage));
      }
    } else {
      dispatch(ActionsDuplicate.requestDuplicates(page));
    }
  }
  const handleDateChange = date => {
    setSelectedDate(date);
  };

  const handleDateChange2 = date => {
    setSelectedDate2(date);
  };
  function searchDuplicatesDate() {
    dispatch(
      ActionsDuplicate.requestDuplicatesForDate(selectedDate, selectedDate2)
    );
  }

  function removeFilterDate() {
    dispatch(ActionsDuplicate.requestDuplicates(1));
  }
  function PrintSlips(element) {
    const dlnk = document.getElementById('linkDoPDF');
    dlnk.href = `https://backnovadublagem.herokuapp.com/boleto/${element.id}`;
    // dlnk.href = 'https://backnovadublagem.herokuapp.com/boleto/6073';
    dlnk.click();
  }
  return (
    <ContainerTotal>
      <Header />
      {loading ? <LinearProgress /> : null}
      <Container>
        <div>
          <HeaderTotal text="Inicio / Duplicatas" />
          <HeaderTable>
            <ContainerValue>
              <TextPrice>Valor total Listado</TextPrice>
              {priceTotal !== null ? (
                <PriceTotal>{priceTotal}</PriceTotal>
              ) : null}
            </ContainerValue>
            <InputWithButton
              labelInput="Pesquise o número da duplicata"
              valueInput={inputNumberDuplicate}
              functionOnChange={text =>
                setInputNumberDuplicate(text.target.value)
              }
              placeholderInput="Número"
              searchForSomething={() => {
                searchDuplicateForNumber();
              }}
              removeFilter={() => {
                removeFilterForNumber();
              }}
            />
            <SearchByDate
              handleDateChange={date => {
                handleDateChange(date);
              }}
              handleDateChange2={date => {
                handleDateChange2(date);
              }}
              valueInitial={selectedDate}
              valueEnd={selectedDate2}
              searchByDate={() => {
                searchDuplicatesDate();
              }}
              removeFilterDate={() => {
                removeFilterDate();
              }}
            />
          </HeaderTable>
          <GridDulicate
            duplicates={duplicates}
            PrintSlips={element => {
              PrintSlips(element);
            }}
            download="boleto.pdf"
            id="linkDoPDF"
          />
        </div>
        <Footer
          pageInitial={page}
          pageLast={lastPage}
          totalItens={total}
          functionInitialPage={() => {
            initialPage();
          }}
          functionBackPage={() => {
            backPageOrder();
          }}
          functionHandlePage={() => {
            paginateDulicate();
          }}
          functionLastPage={() => {
            lastPageOrder();
          }}
        />
      </Container>
    </ContainerTotal>
  );
}
