import produce from 'immer';

const INITIAL_STATE = {
  params: [],
  page: 1,
  total: '0',
  lastPage: null,
  carries: [],
};

export default function params(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@params/REQUEST_PARAMS_SUCESS':
      return produce(state, draft => {
        draft.params = action.payload.data;
      });
    case '@params/SAVE_PAGE':
      return produce(state, draft => {
        draft.page = action.payload.page;
        draft.total = action.payload.total;
        draft.lastPage = action.payload.lastPage;
      });
    case '@params/REQUEST_CARRIER_SUCESS':
      return produce(state, draft => {
        draft.carries = action.payload.carries;
      });
    default:
      return state;
  }
}
