export function handlePageDuplicate() {
  return {
    type: '@duplicate/HANDLE_PAGE_DUPLICATE',
  };
}
export function requestDuplicates(page) {
  return {
    type: '@duplicate/REQUEST_DUPLICATES',
    payload: { page },
  };
}
export function requestDuplicatesSucess(data) {
  return {
    type: '@duplicate/REQUEST_DUPLICATES_SUCESS',
    payload: { data },
  };
}
export function savePage(total, page, lastPage) {
  return {
    type: '@duplicate/SAVE_PAGE',
    payload: { total, page, lastPage },
  };
}
export function requestDuplicatesForNumber(input) {
  return {
    type: '@duplicate/REQUEST_DUPLICATES_FOR_NUMBER',
    payload: { input },
  };
}
export function requestDuplicatesForDate(dateInitial, dateEnd) {
  return {
    type: '@duplicate/REQUEST_DUPLICATES_FOR_DATE',
    payload: { dateInitial, dateEnd },
  };
}
export function savePriceTotalDuplicates(priceTotal) {
  return {
    type: '@duplicate/SAVE_PRICE_TOTAL_DUPLICATE',
    payload: { priceTotal },
  };
}
export function requestDuplicatePrint(idDuplicate) {
  return {
    type: '@duplicate/REQUEST_DUPLICATE_PRINT',
    payload: { idDuplicate },
  };
}
export function requestDuplicatePrintSucess(data) {
  return {
    type: '@duplicate/REQUEST_DUPLICATE_PRINT_SUCESS',
    payload: { data },
  };
}
export function cleanAplicationDuplicates() {
  return {
    type: '@duplicate/CLEAN_APLICATION_DUPLICATES',
  };
}
