import React from 'react';
import { MdSearch, MdClose } from 'react-icons/md';
import PropTypes from 'prop-types';
import { Container } from './styles';
import InputText from '../FieldText';

export default function InputWithButton({
  valueInput,
  functionOnChange,
  labelInput,
  placeholderInput,
  searchForSomething,
  removeFilter,
}) {
  return (
    <Container>
      <InputText
        value={valueInput}
        functionOnChange={functionOnChange}
        label={labelInput}
        placeholder={placeholderInput}
      />

      <button
        type="button"
        onClick={() => {
          searchForSomething();
        }}
      >
        <MdSearch />
      </button>
      <button
        type="button"
        onClick={() => {
          removeFilter();
        }}
      >
        <MdClose />
      </button>
    </Container>
  );
}
InputWithButton.propTypes = {
  functionOnChange: PropTypes.func,
  searchForSomething: PropTypes.func,
  removeFilter: PropTypes.func,
  labelInput: PropTypes.string,
  placeholderInput: PropTypes.string,
  valueInput: PropTypes.string,
};
InputWithButton.defaultProps = {
  functionOnChange: () => {},
  searchForSomething: () => {},
  removeFilter: () => {},
  labelInput: 'Search for something',
  placeholderInput: 'text',
  valueInput: '',
};
