import styled from 'styled-components';

export const Container = styled.div``;

export const Orders = styled.table`
  width: 100%;
  padding: 20px;
  thead th {
    color: #999;
    text-align: left;
    padding-bottom: 20px;
    border: 0;
  }
  thead td {
    text-align: left;
  }
  tbody td {
    text-align: left;
    padding-left: 1px;
  }
  tbody tr {
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }

  strong {
    color: #333;
    display: block;
    padding-bottom: 25px;
  }

  div {
    display: flex;
    align-items: center;
    input {
      border: 1px solid #ddd;
      border-radius: 4px;
      color: #666;
      padding: 6px;
      width: 50px;
    }
    button {
      background: none;
      border: 0px;
      padding: 6px;
    }
  }
`;
