import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import * as OrdersActions from '../../store/modules/orders/actions';
import * as UsersActions from '../../store/modules/users/actions';
import * as ParamsActions from '../../store/modules/params/actions';
import * as ExitActions from '../../store/modules/exit/actions';
import * as DuplicateActions from '../../store/modules/duplicate/actions';
import * as NoteActions from '../../store/modules/note/actions';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';
import Logo from '../../assets/images/template.png';
import LogoAzul from '../../assets/images/logoAzul.png';
import {
  AreaLogo,
  AreaMenu,
  AreaUser,
  Avatar,
  Container,
  ContainerDrawer,
  FontTextMenu,
  ListMenu,
  ListMenuText,
  LogoDrawer,
  Menu,
  TextAvatar,
} from './styles';

// import Input from '../Input';

export default function Header() {
  const dispatch = useDispatch();

  const [user, setUsername] = useState('novadublagem');
  const [permissions, setPermissions] = useState('Administrador');
  useEffect(() => {
    let username = localStorage.getItem('NovaDublagem@Web');
    username = JSON.parse(username);

    const name = username;
    setUsername(name.user);
    setPermissions(name.permission);
  }, []);
  const useStyles = makeStyles({
    list: {
      width: 300,
    },
    fullList: {
      width: 'auto',
    },
  });
  const classes = useStyles();
  const [state, setState] = useState({
    top: false,
  });
  const toggleDrawer = (side, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };
  const handleOrders = () => {
    dispatch(OrdersActions.handleOrders());
    toggleDrawer('left', false);
  };
  const handleUsers = () => {
    dispatch(UsersActions.handleUsers());
    toggleDrawer('left', false);
  };
  const handleParams = () => {
    dispatch(ParamsActions.handleParams());
    toggleDrawer('left', false);
  };
  const exitAplication = () => {
    dispatch(ExitActions.exitAplication());
    toggleDrawer('left', false);
  };
  const handlePageDuplicate = () => {
    dispatch(DuplicateActions.handlePageDuplicate());
    toggleDrawer('left', false);
  };
  const handlePageBilling = () => {
    dispatch(NoteActions.handleBilings());
    toggleDrawer('left', false);
  };

  const sideList = () => (
    <div className={classes.list} role="presentation">
      <AreaLogo>
        <LogoDrawer src={LogoAzul} alt="Nova" />
      </AreaLogo>
      <Divider />

      {permissions === 'Administrador' ? (
        <>
          <List>
            <ListItem>
              <ListMenu>
                <ListMenuText
                  onClick={() => {
                    handleParams();
                  }}
                >
                  <FontTextMenu>Parâmetros</FontTextMenu>
                </ListMenuText>
                <ListMenuText
                  onClick={() => {
                    handleUsers();
                  }}
                >
                  <FontTextMenu>Usuários</FontTextMenu>
                </ListMenuText>
                <ListMenuText
                  onClick={() => {
                    handlePageBilling();
                  }}
                >
                  <FontTextMenu>Faturamento</FontTextMenu>
                </ListMenuText>
                <ListMenuText
                  onClick={() => {
                    handleOrders();
                  }}
                >
                  <FontTextMenu>Pedidos</FontTextMenu>
                </ListMenuText>

                <ListMenuText
                  onClick={() => {
                    exitAplication();
                  }}
                >
                  <FontTextMenu>Sair</FontTextMenu>
                </ListMenuText>
              </ListMenu>
            </ListItem>
          </List>
        </>
      ) : (
        <>
          <List>
            <ListItem>
              <ListMenu>
                <ListMenuText
                  onClick={() => {
                    handleOrders();
                  }}
                >
                  <FontTextMenu>Pedidos</FontTextMenu>
                </ListMenuText>
                <ListMenuText
                  onClick={() => {
                    handlePageDuplicate();
                  }}
                >
                  <FontTextMenu>Duplicatas</FontTextMenu>
                </ListMenuText>
                <ListMenuText
                  onClick={() => {
                    handleOrders();
                  }}
                >
                  <FontTextMenu>Faturamento</FontTextMenu>
                </ListMenuText>

                <ListMenuText
                  onClick={() => {
                    exitAplication();
                  }}
                >
                  <FontTextMenu>Sair</FontTextMenu>
                </ListMenuText>
              </ListMenu>
            </ListItem>
          </List>
          <Divider />
        </>
      )}
    </div>
  );
  return (
    <Container>
      <Drawer open={state.left} onClose={toggleDrawer('left', false)}>
        {sideList('left')}
      </Drawer>
      <ContainerDrawer>
        <AreaMenu>
          <Menu onClick={toggleDrawer('left', true)} />
        </AreaMenu>
        <Link to="/sistema/home">
          <img src={Logo} alt="Logo" />
        </Link>
      </ContainerDrawer>
      <AreaUser>
        <Avatar>
          <TextAvatar>{user.substring(0, 1).toUpperCase()}</TextAvatar>
        </Avatar>
      </AreaUser>
    </Container>
  );
}
