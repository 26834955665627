export const { format: formatPrice } = new Intl.NumberFormat('pt-br', {
  style: 'currency',
  currency: 'BRL',
});
export function dateToEN(date) {
  return date
    .split('/')
    .reverse()
    .join('-');
}
export function convertDateApi(date) {
  const day = date.getDate();
  const mounth = date.getMonth() + 1;
  const year = date.getFullYear();

  const newFomartDate = `${day.toString().length > 1 ? day : `0${day}`}/${
    mounth.toString().length > 1 ? mounth : `0${mounth}`
  }/${year}`;

  return newFomartDate;
}
export function convertDateWithDash(date) {
  const day = date.getDate();
  const mounth = date.getMonth() + 1;
  const year = date.getFullYear();

  const newFomartDate = `${day.toString().length > 1 ? day : `0${day}`}-${
    mounth.toString().length > 1 ? mounth : `0${mounth}`
  }-${year}`;

  return newFomartDate;
}
export function convertDateApiString(date) {
  const dia = date.substring(0, 10).split('-');

  const newDate = `${dia[2]}/${dia[1]}/${dia[0]}`;

  return newDate;
}

export const cnpjMask = value => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2');
};
