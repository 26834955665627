import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import { colors } from '../../styles';

export const Container = styled.div`
  /* largura maxima */
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 630px;
  border-radius: 4px;
  box-shadow: 1px 0 10px rgba(0, 0, 0, 0.1);
  padding: 0 0 0 0;
  margin: 10px;
  h1 {
    font-size: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    svg {
      margin-right: 10px;
    }
  }
`;

export const Orders = styled.table`
  width: 100%;
  padding: 20px;
  thead th {
    color: #999;
    text-align: left;
    padding-bottom: 20px;
    border: 0;
  }
  thead td {
    text-align: left;
  }
  tbody td {
    text-align: left;
    padding-left: 1px;
  }
  tbody tr {
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }

  strong {
    color: #333;
    display: block;
    padding-bottom: 25px;
  }

  div {
    display: flex;
    align-items: center;
    input {
      border: 1px solid #ddd;
      border-radius: 4px;
      color: #666;
      padding: 6px;
      width: 50px;
    }
    button {
      background: none;
      border: 0px;
      padding: 6px;
    }
  }
`;

export const HeaderTable = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  align-items: center;
  padding-left: 20px;
`;
export const ButtonExcel = styled.strong``;

export const ContainerButtonTotal = styled.div`
  display: flex;
  flex-direction: row;
`;
export const ContainerButton = styled.div`
  padding: 2px;
  button {
    border: 0;
    background: ${colors.secundary};
    width: 60px;
    padding: 10px;
    border-radius: 8px;
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
`;
export const FieldText = styled(TextField)`
  width: 250px;
`;

export const ContainerInput = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* input {
    border: 1px solid #ddd;
    border-radius: 4px;
    color: #666;
    padding: 10px;
    width: 250px;
    margin-right: 2px;
  } */
  button {
    border: 0;
    background: ${colors.secundary};
    margin-right: 2px;
    padding: 10px;
    border-radius: 8px;
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
`;
export const ContainerEmission = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;
  /* background: #555; */
  justify-content: center;
  align-items: center;
  /* input {
    border: 1px solid #ddd;
    border-radius: 4px;
    color: #666;
    padding: 10px;
  } */
`;
export const ButtonEmission = styled.button`
  border: 0;
  background: ${colors.secundary};
  margin-left: 2px;
  padding: 10px;
  height: 40px;
  margin-top: 10px;
  border-radius: 8px;
  transition: opacity 0.2s;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;
