import React from 'react';
import PropTypes from 'prop-types';
import { Container, Orders } from './styles';
import { convertDateApiString, formatPrice } from '../../utils/format';

export default function GridOrders({ orders, moreDetailsOrder, id }) {
  return (
    <Container>
      <Orders id={id} key={orders.id}>
        {orders.length === 0 ? null : (
          <>
            <thead>
              <tr>
                <th>Id do Pedido</th>
                <th>Código Industria</th>
                <th>Cliente</th>
                <th>Situação</th>
                <th>Emissão</th>
                <th>Quantidade</th>
                <th>Valor do Pedido</th>
              </tr>
            </thead>

            {orders.map(element => (
              <tbody key={element.id}>
                <tr
                  onClick={() => {
                    moreDetailsOrder(element);
                  }}
                >
                  <td>
                    <strong>{element.id}</strong>
                  </td>
                  <td>
                    <strong>{element.pedido_industria}</strong>
                  </td>
                  <td>
                    <strong>{element.cliente.nome_razao}</strong>
                  </td>
                  <td>
                    <strong>{element.situacao}</strong>
                  </td>
                  <td>
                    <strong>{convertDateApiString(element.emissao)}</strong>
                  </td>
                  <td>
                    <strong>{element.quantidade_pares_total}</strong>
                  </td>
                  <td>
                    <strong>
                      {formatPrice(
                        Number(element.valor_total_pedido).toFixed(2)
                      )}
                    </strong>
                  </td>
                </tr>
              </tbody>
            ))}
          </>
        )}
      </Orders>
    </Container>
  );
}

GridOrders.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object),
  id: PropTypes.string,

  moreDetailsOrder: PropTypes.func,
};
GridOrders.defaultProps = {
  orders: {},
  id: 'table-to-xls',

  moreDetailsOrder: () => {},
};
