import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

export const Container = styled.div`
  padding: 10px;
`;

export const Input = styled(TextField)`
  width: 250px;
`;
