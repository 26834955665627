import React from 'react';
import { Route, Switch } from 'react-router-dom';
// import PrivateRoute from './components/PrivateRouteLogged';
import Login from './pages/Login';
import Home from './pages/Home';
import Users from './pages/Users';
import Billing from './pages/Billing';

// import Orders from './pages/Orders';
import Params from './pages/Params';
import ValidatorClient from './pages/ValidatorClient';
import RegisterClient from './pages/RegisterClient';
import Duplicate from './pages/Duplicate';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/sistema/registerclient" exact component={ValidatorClient} />
      <Route
        path="/sistema/registerclient/client=:token"
        exact
        component={RegisterClient}
      />
      {/* <PrivateRoute path="/sistema/Home" exact component={Home} /> */}
      <Route path="/sistema/home" exact component={Home} />
      <Route path="/sistema/home/Users" exact component={Users} />
      <Route path="/sistema/home/Orders" exact component={Home} />
      <Route path="/sistema/home/Params" exact component={Params} />
      <Route path="/sistema/duplicate" exact component={Duplicate} />
      <Route path="/sistema/billing" exact component={Billing} />
    </Switch>
  );
}
