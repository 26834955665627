import styled from 'styled-components';

export const Container = styled.div`
  overflow: scroll;
`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
export const ContainerText = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 10px;
`;
export const Item = styled.table`
  width: 100%;
  padding: 10px;

  thead th {
    color: #999;
    text-align: left;
    padding-bottom: 12px;
  }
  thead td {
    padding: 12px;
    border-bottom: 1px solid #eee;
  }
  div {
    img {
      display: flex;
      flex-wrap: wrap;
      background: #fff;
      height: 80px;
      width: 80px;
      margin-right: 60px;
    }
  }
  tbody tr {
    overflow: hidden;
    overflow: -moz-hidden-unscrollable;
  }
  > strong {
    color: #333;
    display: block;
  }
`;

export const Text = styled.strong.attrs({
  numberOfLines: 1,
})`
  font-size: 16px;
  font-weight: bold;
  padding-right: 20px;
`;
export const TextBold = styled.strong.attrs({
  numberOfLines: 1,
})`
  font-size: 16px;
  font-weight: bold;
  padding-right: 20px;
`;

export const TextNormal = styled.span`
  font-size: 16px;
  font-weight: 200;
`;
export const HeaderModal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  padding-top: 30px;
  flex-direction: row;
  height: 48px;
  border-radius: 8px;
  margin-bottom: 20px;
  div {
    strong {
      font-size: 24px;
    }
  }
  button {
    border: 0px;
    background: none;
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
`;
