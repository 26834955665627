export function handleOrders() {
  return {
    type: '@orders/HANDLE_ORDERS',
  };
}
export function backHome() {
  return {
    type: '@orders/BACK_HOME',
  };
}
export function requestOrders(page) {
  return {
    type: '@orders/REQUEST_ORDERS',
    payload: { page },
  };
}
export function requestOrdersSucess(data) {
  return {
    type: '@orders/REQUEST_ORDERS_SUCESS',
    payload: { data },
  };
}
export function requestOrdersForId(inputCod) {
  return {
    type: '@orders/REQUEST_ORDERS_FOR_ID',
    payload: { inputCod },
  };
}
export function requestOrdersForEmission(inputEmission1, inputEmission2) {
  return {
    type: '@orders/REQUEST_ORDERS_FOR_EMISSION',
    payload: { inputEmission1, inputEmission2 },
  };
}
export function requestPaginateOrders(page) {
  return {
    type: '@orders/REQUEST_PAGINATE_ORDERS',
    payload: { page },
  };
}
export function savePage(total, page, lastPage) {
  return {
    type: '@orders/SAVE_PAGE',
    payload: { total, page, lastPage },
  };
}
export function cleanAplicationOrders() {
  return {
    type: '@orders/CLEAN_APLICATION_ORDERS',
  };
}
