import styled from 'styled-components';
// import { darken } from 'polished';
import { colors } from '../../styles';

export const Container = styled.div`
  /* largura maxima */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  height: 620px;
  border-radius: 4px;
  box-shadow: 1px 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin: 10px;
  h1 {
    font-size: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    svg {
      margin-right: 10px;
    }
  }
`;
export const HeaderTable = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  align-items: center;
  padding-left: 20px;
`;
export const ContainerButtonTotal = styled.div`
  display: flex;
  flex-direction: row;
`;
export const ContainerButton = styled.div`
  padding: 2px;
  button {
    border: 0;
    background: ${colors.secundary};
    width: 60px;
    padding: 10px;
    border-radius: 8px;
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
`;
export const ButtonExcel = styled.strong``;

export const ContainerUsers = styled.table`
  width: 100%;
  padding: 15px;

  thead th {
    color: #999;
    text-align: left;
    padding-bottom: 30px;
    border: 0;
  }
  thead td {
    text-align: left;
  }
  tbody td {
    width: 250px;
    text-align: left;
    padding-left: 1px;
  }
  tbody tr {
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }

  strong {
    color: #333;
    display: block;
    padding-bottom: 25px;
  }
`;
export const DivButtonTable = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const ButtonTable = styled.strong``;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* input {
    border: 1px solid #ddd;
    border-radius: 4px;
    color: #666;
    padding: 10px;
    width: 250px;
    margin-right: 2px;
  } */
  button {
    border: 0;
    background: ${colors.secundary};
    margin-right: 2px;
    padding: 10px;
    border-radius: 8px;
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
`;
export const ContainerEmission = styled.div`
  padding: 10px;
  input {
    border: 1px solid #ddd;
    border-radius: 4px;
    color: #666;
    padding: 10px;
    margin-right: 2px;
  }
  button {
    border: 0;
    background: ${colors.secundary};
    margin-left: 2px;
    padding: 10px;
    border-radius: 8px;
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
`;
