import React from 'react';
// import { MdRadioButtonUnchecked } from 'react-icons/md';
import PropTypes from 'prop-types';
import { Container, AreaIcon, Icon, IconSet, AreaText, Text } from './styles';

export default function Radius({ text, clickIcon, functionOnClikIcon }) {
  return (
    <Container>
      {clickIcon ? (
        <AreaIcon
          onClick={() => {
            functionOnClikIcon();
          }}
        >
          <Icon size={22} />
        </AreaIcon>
      ) : (
        <AreaIcon
          onClick={() => {
            functionOnClikIcon();
          }}
        >
          <IconSet size={22} />
        </AreaIcon>
      )}
      <AreaText>
        <Text>{text}</Text>
      </AreaText>
    </Container>
  );
}
Radius.propTypes = {
  text: PropTypes.string,
  functionOnClikIcon: PropTypes.func,
  nameIcon: PropTypes.string,
  iconAparence: PropTypes.bool,
  clickIcon: PropTypes.bool,
};
Radius.defaultProps = {
  text: 'Title',
  functionOnClikIcon: () => {},
  nameIcon: 'fonticons',
  iconAparence: false,
  clickIcon: false,
};
