import styled from 'styled-components';
import { colors } from '../../styles';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding: 10px;
`;
export const ContainerButton = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContainerText = styled.div``;

export const Text = styled.strong``;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border: 0;
  margin: 3px;
  border-radius: 8px;
  background: ${colors.secundary};
  transition: opacity 0.2s;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;
