import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import PropTypes from 'prop-types';
import { MdAdd, MdClose, MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import Radius from '../Radius';
// import SelectPermission from '../SelectPermission';
import * as UsersActions from '../../store/modules/users/actions';
import {
  Button,
  ButtonDelete,
  ButtonEdit,
  Container,
  ContainerBody,
  ContainerButton,
  ContainerButtonTotal,
  ContainerInfo,
  ContainerOne,
  ContainerSelect,
  ContainerTwo,
  Footer,
  HeaderModal,
  IconDelete,
  IconEdit,
  Input,
  Select,
  SelectPermission,
  Tables,
  Text,
  TextBold,
} from './styles';

export default function ModalUsers({
  modalVisible,
  handleClose,
  data,
  tables,
}) {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);
  const [radius, setRadius] = useState(true);
  const [itens, setItens] = useState([]);
  const [nameUser, setNameUser] = useState(null);
  const [email, setEmail] = useState(null);
  const [codUser, setCodUSer] = useState(null);
  const [password, setPassword] = useState(null);
  const [selectTable, setSelectTable] = useState(null);
  const [dataOptions, setDataOptions] = useState([]);
  const [status, setStatus] = useState(false);
  const [dataPermission, setDataPermission] = useState(null);
  const [permission, setPermission] = useState([ // eslint-disable-line
    {
      permissao: 'Escolha a permissão',
    },
    {
      permissao: 'Administrador',
    },
    {
      permissao: 'Cliente',
    },
    {
      permissao: 'Representante',
    },
    {
      id: 4,
      permissao: 'Televenda',
    },
  ]);
  useEffect(() => {
    if (tables.length !== 0) {
      setDataOptions(tables);
    }
  }, [tables]);
  useEffect(() => {
    if (data.length !== 0) {
      setItens(data.tabelaprecos);
    }
  }, [data]);
  const useStyles = makeStyles(theme => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '0',
      borderRadius: '8px',
      boxShadow: theme.shadows[5],
    },
  }));
  const classes = useStyles();

  // console.tron.log(data);

  function deleteTableList(index) {
    const newItens = itens.filter((table, indexTable) => { // eslint-disable-line
      if (indexTable !== index) {
        return table;
      }
    });
    toast.info('Tabela removida');
    setItens(newItens);
  }
  const clickRadius = () => {
    setRadius(!radius);
    setStatus(!status);
  };

  function editUser() {
    setDisabled(!disabled);
  }
  function deleteUser() {
    dispatch(UsersActions.deleteUser(data.id));
    dispatch(UsersActions.closeModalUser());
  }
  function addTablePrice() {
    if (selectTable !== null) {
      const tableExist = itens.findIndex(t => {
        return t.id === Number(selectTable);
      });

      if (tableExist !== -1) {
        toast.error('Tabela já existente para este usuário');
      } else {
        const tabela = tables.find(element => {
          return element.id === Number(selectTable);
        });
        setItens([...itens, tabela]);
      }
    }
  }
  useEffect(() => {
    if (data.length !== 0) {
      setNameUser(data.username);
      setCodUSer(data.representante_cod);
      setEmail(data.email);
      setDataPermission(data.permission);
    }
  }, [data]);

  function updateUser() {
    if (dataPermission === null || dataPermission === 'Escolha a permissão') {
      setDataPermission(data.permission);
    }
    if (nameUser !== '' && codUser !== '' && email !== '') {
      if (password === null) {
        // console.tron.log('caqiu aqui');

        dispatch(
          UsersActions.requestUpdateUser(
            nameUser,
            dataPermission,
            status,
            codUser,
            data.password,
            email.trim(),
            itens,
            data
          )
        );
        setPassword(null);
      } else {
        dispatch(
          UsersActions.requestUpdateUser(
            nameUser,
            dataPermission,
            status,
            codUser,
            password,
            email,
            itens,
            data
          )
        );
        setPassword(null);
      }
    }
  }
  // console.tron.log('dataPermission');
  // console.tron.log(dataPermission);
  return (
    <Container>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modalVisible}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalVisible}>
          <div className={classes.paper}>
            <HeaderModal>
              <div>
                {' '}
                <strong>Cadastro de usuários</strong>
              </div>
              <button
                type="button"
                onClick={() => {
                  handleClose();
                }}
              >
                <MdClose size={25} />
              </button>
            </HeaderModal>

            <ContainerInfo>
              <ContainerOne>
                <ContainerBody>
                  <Text>Nome do usuário</Text>
                  <Input
                    placeholder="Nome do usuário"
                    value={nameUser}
                    disabled={disabled}
                    onChange={text => {
                      setNameUser(text.target.value);
                    }}
                    capture={false}
                  />
                  <Text>E-mail</Text>
                  <Input
                    placeholder="E-mail"
                    value={email}
                    disabled={disabled}
                    onChange={text => {
                      setEmail(text.target.value);
                    }}
                  />
                  <Text>Código do representante</Text>
                  <Input
                    placeholder="Código do representante"
                    value={codUser}
                    disabled={disabled}
                    onChange={text => {
                      setCodUSer(text.target.value);
                    }}
                  />
                  <Text>Permissão</Text>
                  <SelectPermission
                    onClick={e => {
                      setDataPermission(e.target.value);
                    }}
                    defaultValue={data.permission}
                  >
                    {permission.map(element => (
                      <>
                        <option value={element.permissao}>
                          {element.permissao}
                        </option>
                      </>
                    ))}
                  </SelectPermission>

                  <Text>Senha</Text>
                  <Input
                    type="password"
                    placeholder="Senha"
                    value={password}
                    onChange={text => {
                      setPassword(text.target.value);
                    }}
                    disabled={disabled}
                  />
                  <Text>Selecine a tabela de preço</Text>
                  <ContainerSelect onSubmit={e => addTablePrice(e)}>
                    <Select
                      name="form_table"
                      onClick={e => {
                        setSelectTable(e.target.value);
                      }}
                    >
                      {dataOptions.map(table => (
                        <option value={table.id}>{table.tabelapreco}</option>
                      ))}
                    </Select>
                    <button
                      type="button"
                      onClick={() => {
                        addTablePrice();
                      }}
                    >
                      <MdAdd size={20} color="#fff" />
                    </button>
                  </ContainerSelect>

                  <Radius
                    clickIcon={radius}
                    functionOnClikIcon={() => {
                      clickRadius();
                    }}
                    text="Usuário Inativo"
                  />
                </ContainerBody>
              </ContainerOne>

              <Tables>
                <ContainerTwo>
                  <thead>
                    <tr>
                      <th>Tabela de preço</th>
                      <th>Editar</th>
                    </tr>
                  </thead>

                  {itens.map((name, index) => (
                    <tbody key={name.id}>
                      <tr>
                        <td>
                          <strong>{name.tabelapreco}</strong>
                        </td>
                        <div>
                          <button
                            type="button"
                            onClick={() => {
                              deleteTableList(index);
                            }}
                          >
                            <MdDelete size={22} />
                          </button>
                        </div>
                      </tr>
                    </tbody>
                  ))}
                </ContainerTwo>
              </Tables>
            </ContainerInfo>
            <Footer>
              <ContainerButtonTotal>
                <ContainerButton>
                  <ButtonEdit
                    onClick={() => {
                      editUser();
                    }}
                  >
                    <IconEdit />
                  </ButtonEdit>
                </ContainerButton>
                <ContainerButton>
                  <ButtonDelete
                    onClick={() => {
                      deleteUser();
                    }}
                  >
                    <IconDelete />
                  </ButtonDelete>
                </ContainerButton>
                <ContainerButton>
                  <Button
                    onClick={() => {
                      updateUser();
                    }}
                  >
                    <TextBold>ATUALIZAR</TextBold>
                  </Button>
                </ContainerButton>
              </ContainerButtonTotal>
            </Footer>
          </div>
        </Fade>
      </Modal>
    </Container>
  );
}
ModalUsers.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  tables: PropTypes.arrayOf(PropTypes.object),
  modalVisible: PropTypes.bool,
  handleClose: PropTypes.func,
};
ModalUsers.defaultProps = {
  data: {},
  tables: {},
  modalVisible: false,
  handleClose: () => {},
};
